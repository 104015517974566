import React, { Component } from 'react';
import axios from "axios";
import LoadingIndicator from '../LoadingIndicator';
import FreeTimeRow from './FreeTimeRow';
import {Redirect, Link} from "react-router-dom";

export default class Raspisanie extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            loading: true,
            data: [],
            services: [],
            service: 0,
            step: 0,
            fio: "",
            tel: "",
            email: "",
            zapis: false,
            chosenDate: "",
            chosenTime: "",
            saveSuccess: false,
            saveSuccessMessage: "",
            setCallbackSuccess: false,
            saveError: false,
        }
    }

    componentDidMount() {
        this.setState({
            tel: (sessionStorage.getItem('userPhone') === null) ? "" : sessionStorage.getItem('userPhone'),
            email: (sessionStorage.getItem('userEmail') === null) ? "" : sessionStorage.getItem('userEmail'),
            fio: (sessionStorage.getItem('userFIO') === null) ? "" : sessionStorage.getItem('userFIO'),
        });

        this.getServices();
    }

    getServices = () => {
        axios({
            method: 'post',
            url: 'https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/get/doctor_services',
            data: {
                doc: this.props.doc['Врач'],
                spec: this.props.spec
            },
            withCredentials: true
        })
            .then((res) => {
                if(res.data.length > 0 ){
                    this.setState({
                        services: res.data,
                    });
                    this.getFreeTime(0);
                }
                else {
                    this.setState({
                        services: [],
                        loading: false
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    services: [],
                    loading: false
                });
            });
    };

    getFreeTime = (ind) => {
        let service = this.state.services[ind]['Номенклатура'];

        let link = 'https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/get/doc_freetime/' + this.props.doc["Врач"] + "/" + service + "/" + this.props.spec;

        axios.get(link)
            .then((res) => {
                this.processData(res.data);
            })
            .catch(function (err) {
                console.log("Ошибка tryToAuth: "+err.message);
            });
    };

    processData = (data) => {
        let times = {};
        let rec = [];
        let date = [];

        if(Array.isArray(data)){
            data.forEach((item) => {
                rec = item['ПериодС'].split("T");

                if(times[rec[0]]) {
                    times[rec[0]].time.push(rec[1].substring(0, 5));
                }
                else {
                    times[rec[0]] = {};
                    date = rec[0].split("-");
                    times[rec[0]].day = date[2] + "." + date[1] + "." + date[0];
                    times[rec[0]].time = [rec[1].substring(0,5)];
                }
            });

            let res = [];
            for( let key in times ){
                res.push(times[key]);
            }

            this.setState({
                data: res,
                loading: false,
                step: 1
            });
        }
    };

    setZapis = (date, time) => {
        this.setState({step: 2, chosenDate: date, chosenTime: time})
    };

    telChange = (event) => {
        const target = event.target;
        let value = target.value.replace(/\D+/gi, "");

        if(value.length == 0){
            value = 8;
        }

        this.setState({
            tel: value
        });
    };

    handleServiceChange = (e) => {
        if(e.target.value !== 0) {
            this.setState({
                loading: true,
                service: e.target.value
            });
            this.getFreeTime(e.target.value);
        }
    };

    setCallback = () => {

        this.setState({loading: true});

        let data = {
            fio: this.state.fio,
            phone: this.state.tel,
            doc: this.props.doc['Фамилия'] + " " + this.props.doc['Имя'] + " " + this.props.doc['Отчество']
        };

        axios.post('https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/set/callback', data, {withCredentials: true})
            .then((res) => {
                if(res.data.success){
                    this.setState({
                        loading: false,
                        setCallbackSuccess: true
                    });
                }
            })
            .catch((err)=>{
                console.log("Ошибка make callback: "+err.message);
                this.setState({ saveError: err.message, loading: false });
            });
    };

    _sendZapis = () => {

        this.setState({
            loading: true
        });

        let data = {
            phone: this.state.tel,
            fio: this.state.fio,
            email: this.state.email,
            doc: this.props.doc,
            chosenDate: this.state.chosenDate,
            chosenTime: this.state.chosenTime,
            chosenService: this.state.services[this.state.service]
        };

        axios.post('https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/set/zapis', data, {withCredentials: true})
            .then((res) => {
                res.data.success ?
                    this.setState({
                        loading: false,
                        saveSuccess: true,
                        step: 3,
                        saveSuccessMessage: "Спасибо! Менеджер свяжется с Вами в течение 5 минут для подтверждения записи."
                    }) :
                    this.setState({ saveError: true, loading: false });
            })
            .catch((err) => {
                //console.log("Ошибка создания записи: "+err.message);
                this.setState({ saveError: err.message, loading: false });
            });
    };

    makeZapis = async () => {
        this.setState({loading: true, step: 3});

        const profile = await sessionStorage.getItem('userProfile');

        let data =
            "phone="+ this.state.tel +
            "&fio="+ this.state.fio +
            "&email="+ this.state.email +
            "&date="+ this.state.chosenDate + " в " + this.state.chosenTime +
            "&filial="+ this.props.filial["Адрес"] +
            "&suf=(БЕЗ СКИДОК)" +
            "&doctor="+ this.props.doc.Фамилия + " " + this.props.doc.Имя + " " + this.props.doc.Отчество +
            "&docId=" + this.props.doc["Врач"] +
            "&userId=" + profile +
            "&chosenDate=" + this.state.chosenDate +
            "&chosenTime=" + this.state.chosenTime +
            "&chosenService=" + this.state.services[this.state.service]['Номенклатура']
        ;

        axios.post('https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/set/zapis1c', data, {withCredentials: true})
            .then((res) => {
                res.data.success ?
                    this.setState({
                        saveSuccess: true,
                        saveSuccessMessage: res.data.message,
                        zapis: res.data.zapis,
                        saveError: false,
                        phoneError: false,
                        fioError: false,
                        loading: false
                    }) :
                    this.setState({ saveError: true, loading: false });
            })
            .catch((err) => {
                console.log("Ошибка SaveSettings: "+err.message);
                this.setState({ saveError: err.message, loading: false });
            });
    };

    makePayment = () => {

        this.setState({loading: true});


        let data = {
            docId: this.props.doc["Врач"],
            chosenService: this.state.services[this.state.service]['Номенклатура'],
            chosenDate: this.state.chosenDate,
            chosenTime: this.state.chosenTime
        };

        axios.post('https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/set/zapis1c', data, {withCredentials: true})
            .then((res) => {
                if(res.data.success){
                    this.processPayment(res.data.zapis, res.data.message);
                }
                this.setState({ saveError: "Ошибка при попытке записи на приём. Попробуйте выбрать другое время.", loading: false });
            })
            .catch((err)=>{
                console.log("Ошибка make zapis: "+err.message);
                this.setState({ saveError: err.message, loading: false });
            });
    };

    processPayment = () => {

        if(this.state.zapis && this.state.zapis.length > 0){

            this.setState({
                step: 4,
                loading: true
            });

            let data = {
                amount: this.state.services[this.state.service]['Цена'],
                doc: this.props.doc['Фамилия'] + " " + this.props.doc['Имя'] + " " + this.props.doc['Отчество'],
                zapis: this.state.zapis, //передаем для последующей простановки скидки, если оплата будет успешной,
                discount: this.state.services[this.state.service]['Скидка'],
                msg: this.state.saveSuccessMessage
            };

            axios("https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/payments/geturl/", {
                method: "post",
                data: data,
                withCredentials: true
            })
                .then((res) => {
                    if(res.data.error){
                        console.log(res.data);
                    }
                    else {
                        this.setState({
                            paymentUrl: res.data.formUrl,
                            loading: false
                        });
                    }
                })
                .catch( (err) => {
                    this.setState({
                        loading: false,
                        auth: false,
                        error: Math.random(),
                        errorText: "Ошибка подключения к серверу. Обновите страницу и попробуйте ещё раз."
                    });
                });
        }
        else {
            this.setState({
                loading: false,
                error: Math.random(),
                errorText: "Ошибка при попытке оплаты."
            });
        }
    };

    processPaymentAndMakeZapis = () => {
        this.setState({
            step: 4,
            loading: true
        });

        let data = {
            amount: this.state.services[this.state.service]['Цена'],
            doc: this.props.doc['Фамилия'] + " " + this.props.doc['Имя'] + " " + this.props.doc['Отчество'],
            docId: this.props.doc['Врач'],
            //zapis: this.state.zapis, //передаем для последующей простановки скидки, если оплата будет успешной,
            discount: this.state.services[this.state.service]['Скидка'],
            msg: this.state.saveSuccessMessage,
            chosenService: this.state.services[this.state.service]['Номенклатура'],
            chosenDate: this.state.chosenDate,
            chosenTime: this.state.chosenTime
        };

        axios("https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/payments/payAndMakeZapis/", {
            method: "post",
            data: data,
            withCredentials: true
        })
            .then((res) => {
                if(res.data.error){
                    console.log(res.data);
                }
                else {
                    this.setState({
                        paymentUrl: res.data.formUrl,
                        loading: false
                    });
                }
            })
            .catch( (err) => {
                this.setState({
                    loading: false,
                    auth: false,
                    error: Math.random(),
                    errorText: "Ошибка подключения к серверу. Обновите страницу и попробуйте ещё раз."
                });
            });
    };

    render(){

        const selector = (
            <div className="form-group">
                <label><b>Услуга</b></label>
                <select
                    className={"form-control"}
                    name="service"
                    value={this.state.service}
                    onChange={this.handleServiceChange}
                >
                    {this.state.services.map((u,i)=>{
                        return (
                            <option value={i} key={i}>
                                {u['Наименование']}
                            </option>
                        );
                    })}
                </select>
            </div>);


        if(this.state.step === 0){
            if(this.state.loading) {
                return (
                    <div className={"d-flex align-items-center justify-content-center mt-5"}>
                        <LoadingIndicator />
                    </div>
                );
            }
            else {
                return <div>{selector}</div>
                ;
            }
        }

        const price = (
            <div className="form-group input-group-lg text-left">
                <label><b>Стоимость</b></label>
                <h5>{this.state.services[this.state.service]['Цена']} рублей</h5>
            </div>
        );

        const duration = (
            <div className="form-group input-group-lg text-left">
                <label><b>Продолжительность</b></label>
                <h5>{this.state.services[this.state.service]['Часы']} час(а)</h5>
            </div>
        );

        if(this.state.step === 1){
            if(this.state.loading)
                return(
                    <div className={"d-flex align-items-center justify-content-center mt-5"}>
                        <LoadingIndicator />
                    </div>
                );
            else
                return (
                    <div>
                        {selector}
                        <hr/>
                        {price}
                        <hr/>
                        <label><b>Свободное время</b></label>
                        <div className={"border rounded p-3"}>
                            {
                                (this.state.data.length > 0) ?
                                    this.state.data.map((u,i)=>{
                                        return <FreeTimeRow
                                            key={i}
                                            item={u}
                                            setZapis={this.setZapis}
                                        />;
                                    })
                                    : (
                                        <div className={"my-3 d-flex flex-column justify-content-center align-items-center"}>
                                            <p>
                                                <i className="far fa-calendar-minus fa-5x mb-3"></i>
                                            </p>
                                            <h4 className={"text-center"}>
                                                Свободного времени для приема не найдено
                                            </h4>
                                            <div className="alert alert-info p-3 text-center mt-3">
                                                {
                                                    (this.state.setCallbackSuccess) ? (
                                                        <div className={"d-flex flex-column justify-content-center align-items-center"}>
                                                            <h5>
                                                                Спасибо! Заказ звонка успешно отправлен в контактный центр.
                                                            </h5>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <h5>
                                                                Закажите обратный звонок — специалист контактного центра перезвонит Вам и подберёт удобное для Вас время.
                                                            </h5>
                                                            <div>
                                                                <div className="form-group input-group-lg col-12 text-left">
                                                                    <label>Имя</label>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control"}
                                                                        name={"fio"}
                                                                        value={this.state.fio}
                                                                        onChange={(e) => this.setState({fio: e.target.value})}
                                                                        required={true}
                                                                    />
                                                                </div>
                                                                <div className="form-group input-group-lg col-12 text-left">
                                                                    <label>Контактный телефон</label>
                                                                    <input
                                                                        type="text"
                                                                        className={"form-control"}
                                                                        name={"tel"}
                                                                        maxLength={11}
                                                                        value={this.state.tel}
                                                                        onChange={this.telChange}
                                                                        required={true}
                                                                    />
                                                                </div>
                                                                <button
                                                                    type="button"
                                                                    className="btn rounded dnk-pink my-2"
                                                                    onClick={this.setCallback}
                                                                >
                                                                    Заказать звонок
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    )
                            }
                        </div>

                    </div>
                );
        }

        if(this.state.step === 2){
            if(this.state.loading) {
                return (
                    <div className={"d-flex align-items-center justify-content-center mt-5"}>
                        <LoadingIndicator />
                    </div>
                );
            }
            return (
                <div className={"row"}>
                    <div className={"col-12"}>
                        <h4 className="my-4">
                            Вы выбрали запись на приём {this.state.chosenDate} в {this.state.chosenTime}
                        </h4>
                    </div>
                    <div className={"col-12 text-center"}>
                        {
                            (this.state.services[this.state.service]['Цена'] === 0) ? (
                                <div className="form-group input-group-lg ">
                                    <button
                                        type="button"
                                        className="btn rounded dnk-blue my-3"
                                        onClick={this.makeZapis}
                                    >
                                        <i className="fas fa-check mr-2"></i>
                                        Записаться на приём
                                    </button>
                                </div>
                            ) : (
                                <div>
                                    <div className="form-group input-group-lg ">
                                        <button
                                            type="button"
                                            className="btn rounded dnk-blue my-3"
                                            onClick={this._sendZapis}
                                        >
                                            Записаться на приём
                                        </button>
                                    </div>
                                    <div className="form-group input-group-lg text-center col-12">
                                        <button
                                            type="button"
                                            className="btn btn-lg rounded dnk-pink my-3"
                                            onClick={ this.processPaymentAndMakeZapis }
                                        >
                                            Записаться и оплатить онлайн
                                        </button>
                                        {
                                            (this.state.services[this.state.service]['Скидка'] > 0) &&
                                            <div className="border rounded p-3">
                                                <p>
                                                    При онлайн-оплате Вы получаете скидку <b>{this.state.services[this.state.service]['Скидка']}%</b>!
                                                </p>
                                                <p>
                                                    Ваша экономия — {this.state.services[this.state.service]['Цена'] * this.state.services[this.state.service]['Скидка'] / 100} рублей.
                                                </p>
                                            </div>
                                        }
                                    </div>
                                </div>

                            )
                        }
                    </div>
                    <div className={"col-12 text-center"}>
                        <div className="form-group input-group-lg ">
                            <button
                                type="button"
                                className="btn rounded btn-outline-secondary my-3"
                                onClick={()=>this.setState({step: 1})}
                            >
                                <i className="fas fa-chevron-left mr-2"></i>
                                Выбрать другое время
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        if(this.state.step === 3) {
            if(this.state.loading) {
                return (
                    <div className={"d-flex align-items-center justify-content-center mt-5"}>
                        <LoadingIndicator />
                    </div>
                );
            }
            if(this.state.saveSuccess) {
                return (
                    <div>
                        <div className={"my-3 d-flex flex-column justify-content-center align-items-center alert alert-info"}>
                            <p>
                                <i className="far fa-check-circle fa-5x mb-3"></i>
                            </p>
                            <h3 className={"d-none text-center"}>
                                Ваша запись подтверждена!
                            </h3>
                            <h4 className={"text-center"}>
                                {this.state.saveSuccessMessage}
                            </h4>
                        </div>
                        {
                            (sessionStorage.getItem('userData') === null) ? null : (
                                <div className="d-none form-group input-group-lg text-center col-12 border rounded p-3">
                                    <button
                                        type="button"
                                        className="btn rounded dnk-pink my-3"
                                        onClick={ this.processPayment }
                                    >
                                        Оплатить онлайн
                                    </button>
                                    <p>
                                        При онлайн-оплате Вы получаете скидку <b>{this.state.services[this.state.service]['Скидка']}%</b>!
                                    </p>
                                    <p>
                                        Ваша экономия — {this.state.services[this.state.service]['Цена'] * this.state.services[this.state.service]['Скидка'] / 100} рублей.
                                    </p>
                                </div>
                            )
                        }
                        <div className="form-group input-group-lg text-center">
                            <Link
                                to={"/medkarta/zapisi"}
                                className="btn rounded btn-outline-secondary my-3"
                            >
                                <i className="fas fa-chevron-left mr-2"></i>
                                В список записей
                            </Link>
                        </div>
                    </div>
                );
            }
            if(this.state.saveError) {
                return (
                    <div className={"my-3 d-flex flex-column justify-content-center align-items-center"}>
                        <p>
                            <i className="fas fa-exclamation-circle fa-5x mb-3"></i>
                        </p>
                        <h4 className={"text-center"}>
                            Не удалось отправить заявку.
                        </h4>
                        <h4 className={"text-center"}>
                            Попробуйте еще раз позднее.
                        </h4>
                        <div className={"text-center"}>
                            <button
                                type="button"
                                className="btn rounded btn-outline-secondary mt-4"
                                onClick={()=>this.setState({
                                    step: 1,
                                    chosenDate: "",
                                    chosenTime: "",
                                    saveSuccess: false,
                                    saveError: false,
                                })}
                            >
                                Выбрать другое время
                            </button>
                        </div>
                    </div>
                );
            }

        }

        if(this.state.step === 4) {
            if(this.state.loading)
                return(
                    <div className={"d-flex align-items-center justify-content-center mt-5"}>
                        <LoadingIndicator />
                    </div>
                );
            else
                return (
                    <iframe
                        src={this.state.paymentUrl}
                        frameBorder="0"
                        className={"w-100"}
                        style={{minHeight: 800}}
                    />
                );
        }
    }
}

/*

{
                        (1000 > 2000) ? (
                            <div>
                                <div className="form-group input-group-lg col-12">
                                    <label>Ваши ФИО</label>
                                    <input
                                        type="text"
                                        className={"form-control"}
                                        name={"fio"}
                                        value={this.state.fio}
                                        onChange={(e) => this.setState({fio: e.target.value})}
                                        required={true}
                                    />
                                </div>
                                <div className="form-group input-group-lg col-12 col-lg-6">
                                    <label>Контактный телефон</label>
                                    <input
                                        type="text"
                                        className={"form-control"}
                                        name={"tel"}
                                        maxLength={11}
                                        value={this.state.tel}
                                        onChange={this.telChange}
                                        required={true}
                                    />
                                </div>
                                <div className="form-group input-group-lg col-12 col-lg-6">
                                    <label>Ваш email</label>
                                    <input
                                        type="email"
                                        className={"form-control"}
                                        name={"email"}
                                        value={this.state.email}
                                        onChange={(e) => this.setState({email: e.target.value})}
                                        required={true}
                                    />
                                </div>
                            </div>
                        ) : null
                    }

 */