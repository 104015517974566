import React, { Component } from 'react';
import axios from "axios";
import {Redirect} from "react-router-dom";
import LoadingIndicator from "../components/LoadingIndicator";

export default class LogOutScreen  extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        axios({
            method: 'get',
            url: 'https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/account/quit',
            withCredentials: true
        }).then((res) => {
            //console.log(res.data);
            sessionStorage.removeItem('userData');
            this.setState({
                loading: false
            })
        })
            .catch(function (err) {
                //this.setState({loading: false});
                console.log("Ошибка tryToAuth: "+err.message);
            });
    }

    render(){
        if(this.state.loading){
            return(
                <div className={"content"}>
                    <div className={"d-flex flex-column vh-100 w-100 justify-content-center align-items-center"}>
                        <LoadingIndicator />
                    </div>
                </div>
            );
        }
        else
        return(
            <Redirect to={"/login"} />
        );
    }
}