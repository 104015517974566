import React, { Component } from 'react';
import axios from "axios";
import {Link} from 'react-router-dom';
import LoadingIndicator from '../LoadingIndicator';

export default class Specialities extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            loading: true,
            data: [],
            type: "future"
        }
    }

    getData = () => {
        let data = "spec="+ this.props.specId;
        //console.log("data: "+data);
        axios.post('https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/get/speciality_doctors', data)
            .then((res) => {
                //console.log(res.data);
                this.groupData(res.data);
            })
            .catch(function (err) {
                console.log("Ошибка get doctors: "+err.message);
                //this.props.navigation.navigate('Error');
            });
    };

    componentDidMount() {
        this.getOffices();
        this.getData();
    }

    getOffices = () => {
        axios.post('https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/get/offices')
            .then((res) => {
                //console.log(res.data);
                this.setState({
                    offices: res.data
                });
            })
            .catch(function (err) {
                console.log("Ошибка get/offices: "+err.message);
                //this.props.navigation.navigate('Error');
            });
    };

    groupData = (doctors) => {
        let offices = this.state.offices;

        offices.forEach(function(office, i, arr) {
            office.docs = [];

            doctors.forEach(function(doc, i, arr) {
                if(office["Офис"] == doc["Офис"]) {
                    office.docs.push(doc);
                }
            });
        });

        this.setState({
            offices: offices,
            loading: false
        });
    };

    render(){
        var priemy = null;
        var rows = null;

        if(this.state.loading)
            return(
                <div className={"content"}>
                    <h3 className={"text-left"}>Адреса клиник, в которых принимает {this.props.specName.toLowerCase()}</h3>
                    <div className={"mt-3"}>
                        <button
                            className={"btn dnk-blue rounded"}
                            onClick={() => this.props.back()}
                        >
                            <i className="fas fa-chevron-left mr-2"></i>Назад
                        </button>
                    </div>
                    <div className={"d-flex align-items-center justify-content-center mt-5"}>
                        <LoadingIndicator />
                    </div>
                </div>
            );
        else
            return (
                <div className={"content"}>
                    <h3 className={"text-left"}>
                        Адреса клиник, в которых принимает {this.props.specName.toLowerCase()}
                    </h3>
                    <div className={"mt-3"}>
                        <button
                            className={"btn dnk-blue rounded"}
                            onClick={() => this.props.back()}
                        >
                            <i className="fas fa-chevron-left mr-2"></i>Назад
                        </button>
                    </div>
                    <div className={"row mt-3"}>
                        {
                            (this.state.offices.length > 0) ?
                                this.state.offices.map((u,i)=>{
                                    if(u.docs.length > 0 ) {
                                        return (
                                            <div key={i} className={"col-xl-4 col-md-6 my-2"}>
                                                <div onClick={() => this.props.selectFilial(u)}>
                                                    <div
                                                        className={"spec-item bg-white rounded shadow p-3 text-center row justify-content-between align-items-center"}>
                                                        <div className={"col-10 text-left"}>
                                                            {u['НаименованиеДляЛК']}
                                                        </div>
                                                        <div className={"col-2"}>
                                                            <i className="fas fa-chevron-right dnk-blue-text fa-2x"></i>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                                : (
                                    <div className={"mt-5 d-flex flex-column justify-content-center align-items-center"}>
                                        <p><i className="fas fa-search fa-5x mb-3"></i></p>
                                        <h4>Не удалось получить список филиалов</h4>
                                    </div>
                                )
                        }
                    </div>
                </div>
            );
    }
}