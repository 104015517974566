import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MainLayout from './layout/MainLayout';

class App extends Component {
  render() {
    return (
        <Router>
            <MainLayout />
        </Router>
    );
  }
}

export default App;