import React, { Component } from 'react';
import axios from "axios";
import dateformat from 'dateformat';
import LoadingIndicator from "../components/LoadingIndicator";

export default class NotificationsScreen extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            loading: true,
            messages: []
        }
    }

    componentDidMount() {
        this.setState({loading: true});

        axios({
            method: 'get',
            url: 'https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/get/messages',
            withCredentials: true
        }).then((res) => {
            let filteredMessages = res.data.filter(u => {
                let date = new Date(u['Дата']);
                let monthAgo = new Date() - ( 60 * 60 * 24 * 30 * 1000 );
                return date.getTime() > monthAgo
            });

            this.setState({
                loading: false,
                messages: filteredMessages
            })
        })
            .catch((err) => {
                this.setState({
                    loading: false,
                    messages: []
                });
                console.log("Ошибка: " + err.message);
            });
    }

    render(){
        let messages = (
            <div className={"text-center my-5 py-5"}>
                <i className="far fa-bell-slash fa-5x dnk-blue-text"></i>
                <h2 className={"my-4"}>
                    Пока нет новых уведомлений
                </h2>
            </div>
        );

        if(this.state.messages.length){
            messages = this.state.messages.map( (u, i) => {
                return (
                    <div className="px-3 py-2 my-3 shadow rounded bg-white overflow-hidden" key={i}>
                        <div className="row">
                            <div className="col-12 my-2 my-lg-3 px-2">
                                <h6>
                                    <b>{dateformat( u['Дата'], "dd.mm.yyyy")}</b>
                                </h6>
                                <div>
                                    {u['Текст']}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            });
        }

        return (
            <div>
                <h3 className={"text-left"}>
                    Уведомления
                </h3>
                {
                    this.state.loading ? (
                        <div className={"d-flex align-items-center justify-content-center mt-5"}>
                            <LoadingIndicator/>
                        </div>
                    ) : messages
                }
            </div>
        );
    }
}