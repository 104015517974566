import React, { Component } from 'react';
import axios from "axios";

export default class Office extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            loading: false,
            data: []
        }
    }

    componentDidMount() {
        /*
        axios({
            method: 'post',
            url: 'https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/get/specialities',
            withCredentials: true
        }).then((res) => {
            console.log(res.data);

        })
            .catch(function (err) {
                //this.setState({loading: false});
                console.log("Ошибка tryToAuth: "+err.message);
            });

         */
    }



    render(){
            return (
                <div className={"content"}>
                    <h3 className={"text-left"}>Выберите врача</h3>
                    <div className={"my-3"}>
                        <button
                            className={"btn dnk-blue rounded"}
                            onClick={() => this.props.back()}
                        >
                            <i className="fas fa-chevron-left mr-2"></i>Назад
                        </button>
                    </div>
                    <div className={""}>
                        <div className={"row"}>
                        {
                            (this.props.filial.docs.length > 0) ?
                                this.props.filial.docs.map((u,i)=>{
                                    return (
                                        <div key={i} className={"col-xl-4 col-md-6 my-2"}>
                                            <div onClick={() => this.props.selectDoc(u)}>
                                                <div className={"spec-item bg-white rounded shadow p-3 text-center row justify-content-between align-items-center"}>
                                                    <div className={"col-10 text-left"}>
                                                        {u['Фамилия'] + " " + u['Имя'] + " " + u['Отчество']}
                                                    </div>
                                                    <div className={"col-2"}>
                                                        <i className="fas fa-chevron-right dnk-blue-text fa-2x"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                : (
                                    <div className={"mt-5 d-flex flex-column justify-content-center align-items-center"}>
                                        <p><i className="fas fa-search fa-5x mb-3"></i></p>
                                        <h4>Не удалось получить список врачей</h4>
                                    </div>
                                )
                        }
                        </div>
                    </div>
                </div>
            );
    }
}