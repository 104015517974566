import React, { Component } from 'react';
import axios from "axios";
import LoadingIndicator from '../LoadingIndicator';

export default class PastPriemRow extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            open: false,
            loading: false
        }
    }

    render() {

        if(this.state.loading) {
            return (
                <div className={"row px-3 py-2 my-3 shadow d-flex align-items-center justify-content-center rounded bg-white"}>
                    <LoadingIndicator small={true} />
                </div>
            );
        }

        return (
            <div className="position-relative px-3 py-2 my-3 shadow rounded bg-white overflow-hidden">
                <div className={"row"}>
                    <div className={"col-12 col-lg-3 col-xl-2 d-flex align-items-center my-2 my-lg-3 px-2"}>
                        <div className={"d-flex align-items-center"}>
                            <i className="far fa-calendar-alt fa-2x mr-3"></i>
                            <div>
                                {this.props.date.substr(8,2) + "." + this.props.date.substr(5,2) + "." + this.props.date.substr(0,4) + " в " + this.props.date.substr(11,5)}
                            </div>
                        </div>
                    </div>
                    <div className={"col-12 col-lg-2 col-xl-3 d-flex align-items-center my-2 my-lg-3 px-2"}>
                        <i className="fas fa-map-marker-alt fa-2x mr-3"></i>
                        {this.props.office}
                    </div>
                    <div className={"col-12 col-lg-5 d-flex align-items-center my-2 my-lg-3 px-2"}>
                        <div style={{width: 40}} className={"mr-3"}>
                            <img src={"/img/vrachi/" + this.props.doc + ".png"} height={"50"} className={"mr-3"} alt=""/>
                        </div>
                        <div>
                            <b>{this.props.doc}</b>
                            <br />
                            {this.props.spec}
                        </div>
                    </div>
                    <div className={"col-12 col-lg-1 col-xl-2 d-flex align-items-center justify-content-end my-2 my-lg-3 px-2"}>
                        {
                            (this.props.documents && this.props.documents.length > 0) ? (
                                <button
                                    className={"btn rounded btn-outline-secondary"}
                                    onClick={()=> this.setState({open: !this.state.open})}
                                >
                                    Результаты <span className="badge dnk-pink badge-pill">{this.props.documents.length}</span>
                                </button>
                            ) : null
                        }
                    </div>
                    {
                        this.state.open &&
                        <div className={"col-12"}>
                            {
                                this.props.documents.map((u,i) => {
                                    let url = u['Ссылка'].replace(/\//giu,'>>>');
                                    url = url.replace(/\\/giu,'>>>');

                                    return (
                                        <div key={i}>
                                            <hr />
                                            <div className={"row"}>
                                                <div className={"col-6 col-lg-3 col-xl-2"}>
                                                    <a href={"https://мойличныйдоктор.рф/protocol/" + url} target={"_blank"} className={"download-btn d-flex align-items-center"}>
                                                        <i className="fas fa-cloud-download-alt fa-2x mr-3"></i>
                                                        <div>
                                                            Скачать
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className={"col-6 col-lg-9"} style={{ overflowWrap: 'break-word' }}>
                                                    {u['Документ']}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }
                </div>
            </div>
        );

    }
}

