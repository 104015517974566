import React, { Component } from 'react';
import axios from "axios";
import PastPriemy from "../components/priemy/PastPriemy";
import LoadingIndicator from "../components/LoadingIndicator";

export default class PastPriemyScreen extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            loading: true,
            priemy: []
        }
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.setState({loading: true});

        axios({
            method: 'get',
            url: 'https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/get/priemy',
            withCredentials: true
        }).then((res) => {
            this.setState({
                loading: false,
                priemy: res.data.reverse()
            })
        })
            .catch(function (err) {
                //this.setState({loading: false});
                console.log("Ошибка: "+err.message);
            });
    };

    render(){
        if(this.state.loading)
            return (
                <div>
                    <h3 className={"text-left"}>
                        Прошедшие приёмы
                    </h3>
                    <div className={"mt-3"}>
                        <button
                            className={"btn dnk-blue rounded"}
                            onClick={() => this.props.history.push("/medkarta")}
                        >
                            <i className="fas fa-chevron-left mr-2"></i>Назад
                        </button>
                    </div>
                    <div className={"d-flex align-items-center justify-content-center mt-5"}>
                        <LoadingIndicator />
                    </div>
                </div>
            );

        else
            return (
                <div>
                    <h3 className={"text-left"}>
                        Прошедшие приёмы
                    </h3>
                    <div className={"mt-3"}>
                        <button
                            className={"btn dnk-blue rounded"}
                            onClick={() => this.props.history.push("/medkarta")}
                        >
                            <i className="fas fa-chevron-left mr-2"></i>Назад
                        </button>
                    </div>
                    <PastPriemy priemy={this.state.priemy} />
                </div>
            );
        }
}