import React, { Component } from 'react';
import axios from "axios";
import LoadingIndicator from "../components/LoadingIndicator";
import CheckBox from "../components/taxes/CheckBox";

export default class SettingsScreen extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            phone: '',
            email: '',
            pass: '',
            pass1: '',
            pass2: '',
            passError: false,
            phoneError: false,
            emailError: false,
            checked: false,
            smsApps: false,
            smsAds: false,
            smsAnaliz: false,
            smsBirthDay: false,
            emailAds: false,
            emailAnaliz: false,
            saveError: false,
            saveSuccess: false,
            refreshing: false
        }
    }

    async componentDidMount() {
        let user = JSON.parse(sessionStorage.getItem('userData'));

        this.setState({
            phone: sessionStorage.getItem('userPhone'),
            email: user["ЭлПочта"],
            pass: "",
            smsAnaliz: user["СмсАнализГотов"],
            smsBirthDay: user["СмсДеньРождения"],
            smsApps: user["СмсЗаписьНаПрием"],
            smsAds: user["СмсРекламныеАкции"],
            emailAds: user["ЭлПочтаРассылка"],
            emailAnaliz: user["ЭлПочтаАнализГотов"],
            refreshing: false
        })
    }

    _checkAndSave = () => {

        let passError;
        let passErrorText;
        let phoneError;
        let phoneErrorText;
        let emailError = false;

        //Проверка совпадения введенных паролей и их минимальной длины
        if(this.state.pass1 != this.state.pass2){
            passError = Math.random();
            passErrorText = 'Пароли не совпадают';
        }
        else if(this.state.pass1.length < 4 && this.state.pass2.length < 4  && this.state.pass1.length > 0 && this.state.pass2.length > 0){
            passError = Math.random();
            passErrorText = 'Длина пароля должна быть не менее 4 символов';
        }
        else passError = false;


        //Проверка минимальной длины телефона
        if(this.state.phone.length < 11){
            phoneError = Math.random();
            phoneErrorText = 'Введите телефон в формате 89123456789';
        }
        else phoneError = false;

        if( !passError && !phoneError && !emailError ){
            console.log("no errors");
            this._saveSettings();
        }
        else {
            this.setState({
                passError: passError,
                passErrorText: passErrorText,
                phoneError: phoneError,
                phoneErrorText: phoneErrorText,
            });
        }
    };

    _saveSettings = () => {
        let data =
            "phone="+ this.state.phone +
            "&email="+ this.state.email +
            "&password="+ this.state.pass +
            "&npassword="+ this.state.pass1 +
            "&npasswordc="+ this.state.pass2 +
            "&subscribe_email="+ this.state.emailAds +
            "&subscribe_email_analize="+ this.state.emailAnaliz +
            "&subscribe_sms_appoint="+ this.state.smsApps +
            "&subscribe_sms_analise="+ this.state.smsAnaliz +
            "&subscribe_sms_birthday="+ this.state.smsBirthDay +
            "&subscribe_sms_advertsing="+ this.state.smsAds;

        axios("https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/set/settings", {
            method: "post",
            data: data,
            withCredentials: true
        })
            .then((res) => {
                console.log("----->>> saving");
                console.log(res.data);
                res.data.success ? this._updateStorage() : this.setState({ saveError: true });
            })
            .catch(function (err) {
                console.log("Ошибка SaveSettings: "+err.message);
                this.setState({ saveError: true });
            });
    };

    _updateStorage = async () => {
        if(this.state.phone != '') {
            sessionStorage.setItem('userPhone', this.state.phone);
        }
        if(this.state.email != ''){
            sessionStorage.setItem('userEmail', this.state.email);
        }

        await this.getData();

        this.setState({
            saveSuccess: true,
            saveError: false,
            passError: false,
            phoneError: false
        });
    };

    render(){
        return(
            <div className={"mb-5"}>
                <h3 className={"mb-3"}>
                    Настройки профиля
                </h3>
                <div className={"mt-3 col-12 col-xl-8 rounded bg-white shadow p-3"}>
                    <div className={"row"}>
                        <div className="form-group input-group-lg col-12 col-md-6">
                            {this.state.phoneError ? (
                                <div className={"dnk-pink rounded p-2 my-3"}>
                                    {this.state.phoneErrorText}
                                </div>
                            ) : (
                                <label><b>Телефон</b></label>
                            )}
                            <input
                                type="text"
                                className={"form-control"}
                                name={"phone"}
                                maxLength={11}
                                value={this.state.phone}
                                onChange={(e) => this.setState({phone: e.target.value.replace(/\D+/gi, "")})}
                                required={true}
                            />
                        </div>
                        <div className="form-group input-group-lg col-12 col-md-6">
                            {this.state.emailError ? (
                                <div className={"dnk-pink rounded p-2 my-3"}>
                                    {this.state.emailErrorText}
                                </div>
                            ) : (
                                <label><b>Email</b></label>
                            )}
                            <input
                                type="text"
                                className={"form-control"}
                                name={"email"}
                                value={this.state.email}
                                onChange={(e) => this.setState({email: e.target.value})}
                                required={true}
                            />
                        </div>
                    </div>
                    <hr/>
                    <div className={"row"}>
                        <div className="form-group input-group-lg col-12 col-md-6">
                            {this.state.passError ? (
                                <div className={"dnk-pink rounded p-2 my-3"}>
                                    {this.state.passErrorText}
                                </div>
                            ) : (
                                <label><b>Новый пароль</b></label>
                            )}
                            <input
                                type="password"
                                className={"form-control"}
                                name={"pass1"}
                                value={this.state.pass1}
                                onChange={(e) => this.setState({pass1: e.target.value})}
                                required={true}
                            />
                        </div>
                        <div className="form-group input-group-lg col-12 col-md-6 ">
                            {this.state.passError ? (
                                <div className={"dnk-pink rounded p-2 my-3"}>
                                    {this.state.passErrorText}
                                </div>
                            ) : (
                                <label><b>Новый пароль повторно</b></label>
                            )}
                            <input
                                type="password"
                                className={"form-control"}
                                name={"pass2"}
                                value={this.state.pass2}
                                onChange={(e) => this.setState({pass2: e.target.value})}
                                required={true}
                            />
                        </div>
                    </div>
                    <hr/>
                    <div className={"col-12 row"}>
                        <div className="form-group input-group-lg col-12 col-md-6">
                            <div>
                                <label><b>Получение email рассылок</b></label>
                                <CheckBox
                                    label={"Готовность анализов"}
                                    checked={()=>this.setState({emailAnaliz: !this.state.emailAnaliz})}
                                    p={this.state.emailAnaliz}
                                />
                                <CheckBox
                                    label={"Рекламные рассылки"}
                                    checked={()=>this.setState({emailAds: !this.state.emailAds})}
                                    p={this.state.emailAds}
                                />
                            </div>
                        </div>
                        <div className="form-group input-group-lg col-12 col-md-6">
                            <div>
                                <label><b>Получение SMS рассылок</b></label>
                                <CheckBox
                                    label={"Запись на приём"}
                                    checked={()=>this.setState({smsApps: !this.state.smsApps})}
                                    p={this.state.smsApps}
                                />
                                <CheckBox
                                    label={"Готовность анализов"}
                                    checked={()=>this.setState({smsAnaliz: !this.state.smsAnaliz})}
                                    p={this.state.smsAnaliz}
                                />
                                <CheckBox
                                    label={"День рождения"}
                                    checked={()=>this.setState({smsBirthDay: !this.state.smsBirthDay})}
                                    p={this.state.smsBirthDay}
                                />
                                <CheckBox
                                    label={"Рекламные рассылки"}
                                    checked={()=>this.setState({smsAds: !this.state.smsAds})}
                                    p={this.state.smsAds}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group input-group-lg text-center col-12">
                        {
                            (this.state.saveSuccess) ? (
                                <div className={"dnk-blue rounded p-2 my-3"}>
                                    <h4>
                                        Заявка успешно отправлена
                                    </h4>
                                    <p className={"mb-0"}>
                                        Срок подготовки документов - 14 рабочих дней.
                                    </p>
                                    <p>
                                        Когда документы будут готовы, Вам на мобильный телефон, указанный в Личном кабинете, придет SMS-уведомление.
                                    </p>
                                </div>
                            ) : null
                        }

                        {
                            (this.state.loading) ? (
                                <LoadingIndicator />
                            ) : null
                        }

                        {
                            (!this.state.loading && !this.state.saveSuccess) ? (
                                <button
                                    type="button"
                                    className="btn rounded dnk-blue my-2"
                                    onClick={this._checkAndSave}
                                >
                                    Сохранить
                                </button>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        );
    }
}