import React, { Component } from 'react';
import PastPriemRow from "./PastPriemRow";

export default class PastPriemy extends Component {
    constructor() {
        super(...arguments);
    }

    render(){
        var count = 0;
        var priemy = null;
        if(this.props.priemy.length > 0){
            priemy = this.props.priemy.map((u,i) => {

                var date = new Date(u['Дата']);
                var now = new Date();

                if(date < now){
                    count++;
                    return (
                        <PastPriemRow
                            key={i}
                            date={u['Дата']}
                            doc={u['Специалист']}
                            office={u['Офис']}
                            spec={u['Специальность']}
                            documents={u['Документы']}
                        />
                    );
                }
                else return null;
            });
        }

        if(count > 0)
            return (
                <div>
                    {priemy}
                </div>
            );
        else return (
            <div className={"mt-5 d-flex flex-column justify-content-center align-items-center"}>
                <p><i className="fas fa-search fa-5x mb-3"></i></p>
                <h3>У вас пока не было приёмов</h3>
            </div>
        );
    }
}



