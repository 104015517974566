import React, { Component } from 'react';

export default class BonusScreen  extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            user: {},
            loading: false
        }
    }

    render(){

        return(
            <div className={"flex-grow-1"}>
                <div className={"pb-5"}>
                    <div className={"mx-auto col-12 col-md-8 mt-5"}>
                        <h3 className={"text-center mb-4"}>
                            Бонусная программа «Копите здоровье»
                        </h3>
                        <div className="rounded bg-white shadow p-4">
                            <p>
                                Мы заботимся о своих пациентах и хотим, чтобы они получали услуги с выгодой, поэтому рады рассказать о бонусной программе «Копите здоровье».
                            </p>
                            <p>
                                Бонусы дают возможность воспользоваться услугами клиники на еще более выгодных условиях. При оплате бонусами – ваша скидка составит 50%. 1 бонус = 1 рубль.
                            </p>
                        </div>

                        <div className="mt-4 rounded bg-white shadow p-4">
                            <p>
                                Мы очень ценим пациентов, впервые посетивших нашу клинику и начисляем перед первым приемом 250 бонусов <b>"Привет"</b>, которые можно потратить на все виды услуг клиники, кроме акционных.
                            </p>
                            <b>
                                Срок действия: 7 дней.
                            </b>
                        </div>

                        <div className="mt-4 rounded bg-white shadow p-4">
                            <p>
                                Нам также важно, чтобы вы получали услуги ДНК Клиники выгодно, поэтому сразу после первого посещения - <b>начисляем 350 бонусов</b>, которые можно использовать на все виды услуг, кроме акционных.
                            </p>
                            <b>
                                Срок действия: 30 дней.
                            </b>
                        </div>

                        <div className="mt-4 rounded bg-white shadow p-4">
                            <p>
                                Мы благодарны, что вы выбираете ДНК Клинику и остаетесь с нами и после второго посещения <b>начисляем 500 бонусов</b>, потратить которые, можно на все виды услуг, кроме акционных.
                            </p>
                            <b>
                                Срок действия: 30 дней.
                            </b>
                        </div>

                        <div className="mt-4 rounded bg-white shadow p-4">
                            <p>
                                Чтобы порадовать наших пациентов в преддверие "Дня рождения" (за 1 месяц) <b>начисляем 1000 бонусов</b>, которые можно использовать на услуги пластической хирургии (только оперативное вмешательство), косметологии и массаж. Такие бонусы получат пациенты со статусом "Серебряный" (потратив на услуги клиники от 0 до 30 000 рублей).
                            </p>
                            <b>
                                Срок действия: 60 дней.
                            </b>
                        </div>

                        <div className="mt-4 rounded bg-white shadow p-4">
                            <p>
                                За месяц до "Дня рождения" пациентам со статусом <b>"Золотой"</b> (получившим услуги на сумму от 30 000 до 100 000 рублей мы также <b>начисляем 1500 бонусов</b>, которые можно использовать на услуги пластической хирургии (только оперативное вмешательство), косметологии и массаж.
                            </p>
                            <b>
                                Срок действия: 60 дней.
                            </b>
                        </div>

                        <div className="mt-4 rounded bg-white shadow p-4">
                            <p>
                                Пациентам со статусом <b>"Платиновый"</b> (получившим услуги на сумму свыше 100 000 рублей), <b>начисляем 2000 бонусов</b>, которые можно потратить на услуги пластической хирургии (только оперативное вмешательство), косметологии и массаж
                            </p>
                            <b>
                                Срок действия: 60 дней.
                            </b>
                        </div>

                        <div className="mt-4 rounded bg-white shadow p-4">
                            Пациенты, получившие услуги на сумму свыше 250 000 рублей получают особый бонус - несгораемую скидку на все услуги клиники в размере 15%.
                        </div>

                        <div className="mt-4 rounded bg-white shadow p-4">
                            <p>
                                Узнать баланс Вашего бонусного счета вы можете в любое удобное время в личном кабинете.
                            </p>
                            <b>
                                Спасибо, за то, что выбираете ДНК Клинику!
                            </b>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
