import React, { Component } from 'react';
import axios from "axios";
import {Link} from 'react-router-dom';
import LoadingIndicator from '../LoadingIndicator';

export default class Specialities extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            loading: true,
            data: [],
            type: "future"
        }
    }

    componentDidMount() {
        axios({
            method: 'post',
            url: 'https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/get/specialities',
            withCredentials: true
        }).then((res) => {
            this.setState({
                loading: false,
                data: res.data
            });
            //console.log(res.data);
            //this.filterData(res.data);
        })
            .catch(function (err) {
                //this.setState({loading: false});
                console.log("Ошибка specialities: "+err.message);
            });
    }

    filterData = (data) => {
        let filtered = [];
        let approved = [
            "Акушер-гинеколог",
            "Аллерголог-Иммунолог",
            "Андролог",
            "Артролог",
            "Гастроэнтеролог",
            "Гемостазиолог",
            "Генетик",
            "Гинеколог-онколог",
            "Дерматовенеролог",
            "Косметолог",
            "ЛОР-врач",
            "Нефролог",
            "Онколог-маммолог",
            "Педиатр",
            "Пластический хирург",
            "Репродуктолог-гинеколог",
            "Терапевт",
            "Уролог",
            "Эндокринолог"
        ];

        data.forEach(function(item, i) {
            approved.forEach(function(app, k) {
                if(item["Представление"] == app) filtered.push(item);
            });
        });

        this.setState({
            loading: false,
            data: filtered
        });
    };

    render(){

        if(this.state.loading)
            return(
                <div className={"content"}>
                    <h3 className={"text-left"}>Выберите специализацию врача</h3>
                    <div className={"d-flex align-items-center justify-content-center mt-5"}>
                        <LoadingIndicator />
                    </div>
                </div>

            );
        else
            return (
                <div className={"content"}>
                    <h3 className={"text-left"}>Выберите специализацию врача</h3>
                    <div className={"row mt-3"}>
                        {
                            (this.state.data.length > 0) ?
                                this.state.data.map((u,i)=>{
                                    return (
                                        <div key={i} className={"col-xl-4 col-md-6 my-2"}>
                                            <div onClick={() => this.props.selectSpec(u['Специальность'], u['Представление'])}>
                                                <div className={"spec-item bg-white rounded shadow p-3 text-center row justify-content-between align-items-center"}>
                                                    <div>
                                                        {u['Представление']}
                                                    </div>
                                                    <div>
                                                        <i className="fas fa-chevron-right dnk-blue-text fa-2x"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                : (
                                    <div className={"mt-5 d-flex flex-column justify-content-center align-items-center"}>
                                        <p><i className="fas fa-search fa-5x mb-3"></i></p>
                                        <h4>Не удалось получить список специализаций</h4>
                                    </div>
                                )
                        }
                    </div>

                </div>
            );
    }
}