import React, { Component } from 'react';
import axios from "axios";
import LoadingIndicator from "../components/LoadingIndicator";
import CheckBox from "../components/taxes/CheckBox";

export default class TaxScreen extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            fio1:'',
            fio2:'',
            inn:'',
            year1: '',
            year2: '',
            year3: '',
            year4: '',
            year5: '',
            year6: '',
            filial1: '',
            filial2: '',
            filial3: '',
            filial4: '',
            filial5: '',
            filial6: '',
            filial7: '',
            filial8: '',
            filial9: '',
            vosstanovitFIO:'',
            vosstanovitDogovor: '',
            getDocs: '',
            passError: false,
            phoneError: false,
            emailError: false,
            yearError: false,
            yearErrorText: "Укажите годы, за которые требуется справка",
            saveError: false,
            saveSuccess: false,
            refreshing: false,
            loading: false
        }
    }

    _checkAndSave = () => {
        //console.log('SAVE');
        let fioError;
        let fioErrorText;
        let yearError;
        let yearErrorText;
        let filialError;
        let filialErrorText;
        let getDocsError;
        let getDocsErrorText;

        //Проверка имени налогоплательщика
        if(this.state.fio1 == ""){
            fioError = Math.random();
            fioErrorText = 'Заполните ФИО налогоплательщика';
        }
        else fioError = false;

        //Проверка выбора года
        if(!this.state.year6 && !this.state.year5 && !this.state.year4 && !this.state.year3 && !this.state.year2){
            yearError = Math.random();
            yearErrorText = 'Укажите годы, за которые требуется справка';
        }
        else yearError = false;

        //Проверка выбора филиала получения услуг
        if(!this.state.filial1 && !this.state.filial2 && !this.state.filial3 && !this.state.filial4  && !this.state.filial5  && !this.state.filial6 && !this.state.filial7 && !this.state.filial8 && !this.state.filial9 ){
            filialError = Math.random();
            filialErrorText = 'Укажите мед.центр (или несколько), в котором получали услуги';
        }
        else filialError = false;

        //Проверка выбора филиала получения документов
        if(!this.state.getDocs){
            getDocsError = Math.random();
            getDocsErrorText = 'Укажите мед.центр, в котором хотите получить документы';
        }
        else getDocsError = false;


        if( !fioError && !yearError && !filialError && !getDocsError ){
            //console.log("no errors");
            this.setState({ loading: true });
            this._saveSettings();
        }
        else {
            this.setState({
                fioError: fioError,
                fioErrorText: fioErrorText,
                yearError: yearError,
                yearErrorText: yearErrorText,
                filialError: filialError,
                filialErrorText: filialErrorText,
                getDocsError: getDocsError,
                getDocsErrorText: getDocsErrorText,
                loading: false
            });
        }
    };

    _saveSettings = async () => {
        let birthDate = sessionStorage.getItem('userBirthday');

        let data =
            "fio1="+ this.state.fio1 +
            "&birthDate=" + birthDate +
            "&fio2="+ this.state.fio2 +
            "&inn="+ this.state.inn +
            "&filial1="+ this.state.filial1 +
            "&filial2="+ this.state.filial2 +
            "&filial3="+ this.state.filial3 +
            "&filial4="+ this.state.filial4 +
            "&filial5="+ this.state.filial5 +
            "&filial6="+ this.state.filial6 +
            "&filial7="+ this.state.filial7 +
            "&filial8="+ this.state.filial8 +
            "&filial9="+ this.state.filial9 +
            "&year2="+ this.state.year2 +
            "&year3="+ this.state.year3 +
            "&year4="+ this.state.year4 +
            "&year5="+ this.state.year5 +
            "&year6="+ this.state.year6 +
            "&getDocs="+ this.state.getDocs +
            "&vosstanovitDogovor="+ this.state.vosstanovitDogovor +
            "&vosstanovitFIO="+ this.state.vosstanovitFIO;

        axios("https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/set/taxes", {
            method: "post",
            data: data,
            withCredentials: true
        })
            .then((res) => {
                //console.log("----->>> saving");
                //console.log(res.data);
                res.data.success ?
                    this.setState({
                        saveSuccess: true,
                        saveError: false,
                        fioError: false,
                        filialError: false,
                        yearError: false,
                        getDocsError: false,
                        loading: false
                    })
                    :
                    this.setState({ saveError: true, loading: false });
            })
            .catch(function (err) {
                //console.log("Ошибка SaveSettings: "+err.message);
                this.setState({ saveError: true });
            });
    };

    render(){
        return(
            <div className={"mb-5"}>
                <h3 className={"mb-3"}>
                    Налоговый вычет
                </h3>
                <h5>
                    Заявление на формирование справки для налогового вычета
                </h5>
                <div className={"row mt-3 rounded shadow bg-white p-3"}>
                    <div className={"col-12 row"}>
                        <div className="form-group input-group-lg col-12 col-md-6 col-lg-4">
                            {this.state.fioError ? (
                                <div className={"dnk-pink rounded p-2 my-3"}>
                                    {this.state.fioErrorText}
                                </div>
                            ) : (
                                <label><b>ФИО налогоплательщика</b></label>
                            )}
                            <input
                                type="text"
                                className={"form-control"}
                                name={"fio1"}
                                value={this.state.fio1}
                                onChange={(e) => this.setState({fio1: e.target.value})}
                                required={true}
                            />
                        </div>
                        <div className="form-group input-group-lg col-12 col-md-6 col-lg-4">
                            <label><b>ИНН налогоплательщика</b></label>
                            <input
                                type="text"
                                className={"form-control"}
                                name={"inn"}
                                value={this.state.inn}
                                onChange={(e) => this.setState({inn: e.target.value.replace(/\D+/gi, "")})}
                                required={true}
                            />
                        </div>
                        <div className="form-group input-group-lg col-12 col-md-6 col-lg-4">
                            <label><b>ФИО лица, получившего услугу</b></label>
                            <input
                                type="text"
                                className={"form-control"}
                                name={"fio2"}
                                value={this.state.fio2}
                                onChange={(e) => this.setState({fio2: e.target.value})}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className={"col-12 row"}>
                        <div className="form-group input-group-lg col-12 col-md-6 col-lg-4">
                            <div>
                                {this.state.filialError ? (
                                    <div className={"dnk-pink rounded p-2 my-3"}>
                                        {this.state.filialErrorText}
                                    </div>
                                ) : (
                                    <label><b>В каком медицинском центре получали услуги</b></label>
                                )}
                                <CheckBox
                                    label={"Челябинск, ул. 40-лет Победы, 28В"}
                                    checked={(val)=>this.setState({filial1: val})}
                                    p={this.state.filial1}
                                />
                                <CheckBox
                                    label={"Челябинск, ул. Бр. Кашириных, 107"}
                                    checked={(val)=>this.setState({filial2: val})}
                                    p={this.state.filial2}
                                />
                                <CheckBox
                                    label={"Челябинск, пр. Комарова, 133"}
                                    checked={(val)=>this.setState({filial3: val})}
                                    p={this.state.filial3}
                                />
                                <CheckBox
                                    label={"Челябинск, ул. Энгельса, 44"}
                                    checked={(val)=>this.setState({filial4: val})}
                                    p={this.state.filial4}
                                />
                                <CheckBox
                                    label={"Челябинск, ул. Яблочкина, 3"}
                                    checked={(val)=>this.setState({filial5: val})}
                                    p={this.state.filial5}
                                />
                                <CheckBox
                                    label={"Челябинск, пр. Комсомольский, 103"}
                                    checked={(val)=>this.setState({filial6: val})}
                                    p={this.state.filial6}
                                />
                                <CheckBox
                                    label={"Магнитогорск, ул. Гагарина, 24"}
                                    checked={(val)=>this.setState({filial7: val})}
                                    p={this.state.filial7}
                                />
                                <CheckBox
                                    label={"Челябинск, ул. Худякова, 13"}
                                    checked={(val)=>this.setState({filial8: val})}
                                    p={this.state.filial8}
                                />
                                <CheckBox
                                    label={"Челябинск, ул. Пушкина, 27а"}
                                    checked={(val)=>this.setState({filial9: val})}
                                    p={this.state.filial9}
                                />
                            </div>
                        </div>
                        <div className="form-group input-group-lg col-12 col-md-6 col-lg-4">
                            <div className={"mb-3"}>
                                {this.state.yearError ? (
                                    <div className={"dnk-pink rounded p-2 my-3"}>
                                        {this.state.yearErrorText}
                                    </div>
                                ) : (
                                    <label><b>За какие годы требуется справка</b></label>
                                )}
                                <div className={"row"}>
                                    <CheckBox
                                        label={"2018"}
                                        checked={(val)=>this.setState({year4: val})}
                                        p={this.state.year4}
                                    />
                                    <CheckBox
                                        label={"2019"}
                                        checked={(val)=>this.setState({year5: val})}
                                        p={this.state.year5}
                                    />
                                    <CheckBox
                                        label={"2020"}
                                        checked={(val)=>this.setState({year6: val})}
                                        p={this.state.year6}
                                    />
                                </div>
                            </div>
                            {this.state.getDocsError ? (
                                <div className={"dnk-pink rounded p-2 my-3"}>
                                    {this.state.getDocsErrorText}
                                </div>
                            ) : (
                                <label><b>В каком медицинском центре удобно забрать документы</b></label>
                            )}
                            <select
                                name="getDocs"
                                className={"form-control mb-5"}
                                onChange={(e)=>this.setState({getDocs: e.target.value})}
                            >
                                <option value=""></option>
                                <option value="Челябинск, ул. 40-лет Победы, 28В">Челябинск, ул. 40-лет Победы, 28В</option>
                                <option value="Челябинск, пр. Комарова, 133">Челябинск, пр. Комарова, 133</option>
                                <option value="Челябинск, ул. Яблочкина, 3">Челябинск, ул. Яблочкина, 3</option>
                                <option value="Магнитогорск, ул. Гагарина, 24">Магнитогорск, ул. Гагарина, 24</option>
                                <option value="Челябинск, ул. Худякова, 13">Челябинск, ул. Худякова, 13</option>
                            </select>
                            <hr/>
                            <label><b>Восстановление договора на оказание медицинских услуг</b></label>
                            <CheckBox
                                label={"Требуется восстановление договора"}
                                checked={()=>this.setState({vosstanovitDogovor: !this.state.vosstanovitDogovor})}
                                p={this.state.vosstanovitDogovor}
                            />
                            <div className="form-group input-group-lg mt-3">
                                <input
                                    type="text"
                                    className={"form-control"}
                                    name={"fio"}
                                    placeholder={"ФИО для договора"}
                                    value={this.state.vosstanovitFIO}
                                    onChange={(e) => this.setState({vosstanovitFIO: e.target.value})}
                                    required={true}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-group input-group-lg text-center col-12">
                        {
                            (this.state.saveSuccess) ? (
                                <div className={"dnk-blue rounded p-2 my-3"}>
                                    <h4>
                                        Заявка успешно отправлена
                                    </h4>
                                    <p className={"mb-0"}>
                                        Срок подготовки документов - 14 рабочих дней.
                                    </p>
                                    <p>
                                        Когда документы будут готовы, Вам на мобильный телефон, указанный в Личном кабинете, придет SMS-уведомление.
                                    </p>
                                </div>
                            ) : null
                        }

                        {
                            (this.state.loading) ? (
                                <LoadingIndicator />
                            ) : null
                        }

                        {
                            (!this.state.loading && !this.state.saveSuccess) ? (
                                <button
                                    type="button"
                                    className="btn rounded dnk-blue my-2"
                                    onClick={this._checkAndSave}
                                >
                                    Отправить
                                </button>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        );
    }
}