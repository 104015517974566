import React, { Component } from 'react';

export default class ErrorScreen extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {

        }
    }

    render() {
        return (
            <div className={"d-flex flex-column align-items-center justify-content-center p-5 mx-auto"}>
                <i className="fas fa-search fa-5x mb-5"></i>
                <h1>Страница не найдена</h1>
            </div>
        );
    }
}