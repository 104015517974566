import React, { Component } from 'react';

export default class ContactScreen extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {                            
        }
    }

    render(){

            return (
                <div className={"content"}>
                    <div className={"mt-3"}>
                        <h3 className={"text-left"}>Контакты</h3>
                        <div className={"row mt-3"}>
                            <div className={"col-12 col-mg-6 col-xl-6"}>
                                <div className={"shadow rounded p-3 dnk-blue"}>
                                    <h4>
                                        Единый контактный центр
                                    </h4>
                                    <hr className={"bg-white"}/>
                                    <div>
                                        <i className="fas fa-phone mr-3"></i>
                                        8 (351) 214-23-03 (Челябинск)
                                    </div>
                                    <hr className={"bg-white"}/>
                                    <div>
                                        <i className="fas fa-phone mr-3"></i>
                                        8 (3519) 21-03-03 (Магнитогорск)
                                    </div>
                                    <hr className={"bg-white"}/>
                                    <div>
                                        <i className="fas fa-at mr-3"></i>
                                        contact@medconsult.pro
                                    </div>
                                    <hr className={"bg-white"}/>
                                    <div>
                                        <i className="fas fa-clock mr-3"></i>
                                        Пн — Пт: с 07:30 до 21:00
                                    </div>
                                    <hr className={"bg-white"}/>
                                    <div>
                                        <i className="fas fa-clock mr-3"></i>
                                        Сб — Вс: с 08:00 до 20:00
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h4 className={"mt-5 d-none"}>
                            <img src="/img/dnk.svg" className={"mr-3"} height={50} alt=""/>
                            ДНК Клиника
                        </h4>                                                

                        <div className={"row mt-3"}>
                            <div className={"col-12 col-mg-6 col-xl-6"}>
                                <div className={"shadow rounded bg-white  p-3 my-3"}>
                                    <h4>
                                        ДНК Клиника 40-лет Победы, 28В
                                    </h4>
                                    <hr/>
                                    <div>
                                        <i className="fas fa-phone dnk-blue-text mr-3"></i>
                                        8 (351) 214-23-03
                                    </div>
                                    <hr/>
                                    <a className={"d-block"} href={"https://dnk174.ru"} target={"_blank"}>
                                        <i className="fas fa-globe dnk-blue-text mr-3"></i>
                                        dnk174.ru
                                    </a>
                                    <hr/>                                    
                                    <a className={"d-block"} href={"https://go.2gis.com/lyk9q"} target={"_blank"}>
                                        <i className="fas fa-map-marker-alt dnk-blue-text mr-3"></i>
                                        Показать на карте
                                    </a>                                    
                                </div>
                            </div>

                            <div className={"col-12 col-mg-6 col-xl-6"}>
                                <div className={"shadow rounded bg-white  p-3 my-3"}>
                                    <h4>
                                        ДНК Клиника Яблочкина, 3
                                    </h4>
                                    <hr/>
                                    <div>
                                        <i className="fas fa-phone dnk-blue-text mr-3"></i>
                                        8 (351) 214-23-03
                                    </div>
                                    <hr/>
                                    <a className={"d-block"} href={"https://dnk174.ru"} target={"_blank"}>
                                        <i className="fas fa-globe dnk-blue-text mr-3"></i>
                                        dnk174.ru
                                    </a>
                                    <hr/>                                    
                                    <a className={"d-block"} href={"https://go.2gis.com/jutd5"} target={"_blank"}>
                                        <i className="fas fa-map-marker-alt dnk-blue-text mr-3"></i>
                                        Показать на карте
                                    </a>                                    
                                </div>
                            </div>

                            <div className={"col-12 col-mg-6 col-xl-6"}>
                                <div className={"shadow rounded bg-white  p-3 my-3"}>
                                    <h4>
                                        ДНК Клиника Комарова, 133
                                    </h4>
                                    <hr/>
                                    <div>
                                        <i className="fas fa-phone dnk-blue-text mr-3"></i>
                                        8 (351) 214-23-03
                                    </div>
                                    <hr/>
                                    <a className={"d-block"} href={"https://dnk174.ru"} target={"_blank"}>
                                        <i className="fas fa-globe dnk-blue-text mr-3"></i>
                                        dnk174.ru
                                    </a>
                                    <hr/>                                    
                                    <a className={"d-block"} href={"https://go.2gis.com/xsqha"} target={"_blank"}>
                                        <i className="fas fa-map-marker-alt dnk-blue-text mr-3"></i>
                                        Показать на карте
                                    </a>                                    
                                </div>
                            </div>                            

                            <div className={"col-12 col-mg-6 col-xl-6"}>
                                <div className={"shadow rounded bg-white  p-3 my-3"}>
                                    <h4>
                                        ДНК Клиника Магнитогорск, Гагарина, 24
                                    </h4>
                                    <hr/>
                                    <div>
                                        <i className="fas fa-phone dnk-blue-text mr-3"></i>
                                        8 (3519) 21-03-03
                                    </div>
                                    <hr/>
                                    <a className={"d-block"} href={"https://dnk174.ru"} target={"_blank"}>
                                        <i className="fas fa-globe dnk-blue-text mr-3"></i>
                                        dnk174.ru
                                    </a>
                                    <hr/>                                    
                                    <a className={"d-block"} href={"https://go.2gis.com/d66lk"} target={"_blank"}>
                                        <i className="fas fa-map-marker-alt dnk-blue-text mr-3"></i>
                                        Показать на карте
                                    </a>                                    
                                </div>
                            </div>                            

                            <div className={"col-12 col-mg-6 col-xl-6"}>
                                <div className={"shadow rounded bg-white  p-3 my-3"}>
                                    <h4>
                                        Клиника ЗАБОТА Энгельса, 44
                                    </h4>
                                    <hr/>
                                    <div>
                                        <i className="fas fa-phone dnk-blue-text mr-3"></i>
                                        8 (351) 214-53-53
                                    </div>
                                    <hr/>
                                    <a className={"d-block"} href={"https://doctorzabota.ru"} target={"_blank"}>
                                        <i className="fas fa-globe dnk-blue-text mr-3"></i>
                                        doctorzabota.ru
                                    </a>
                                    <hr/>                                    
                                    <a className={"d-block"} href={"https://go.2gis.com/m5j98"} target={"_blank"}>
                                        <i className="fas fa-map-marker-alt dnk-blue-text mr-3"></i>
                                        Показать на карте
                                    </a>                                    
                                </div>
                            </div>

                            <div className={"col-12 col-mg-6 col-xl-6"}>
                                <div className={"shadow rounded bg-white  p-3 my-3"}>
                                    <h4>
                                        Клиника ЗАБОТА Бр. Кашириных, 107
                                    </h4>
                                    <hr/>
                                    <div>
                                        <i className="fas fa-phone dnk-blue-text mr-3"></i>
                                        8 (351) 214-53-53
                                    </div>
                                    <hr/>
                                    <a className={"d-block"} href={"https://doctorzabota.ru"} target={"_blank"}>
                                        <i className="fas fa-globe dnk-blue-text mr-3"></i>
                                        doctorzabota.ru
                                    </a>
                                    <hr/>                                    
                                    <a className={"d-block"} href={"https://go.2gis.com/evb4v"} target={"_blank"}>
                                        <i className="fas fa-map-marker-alt dnk-blue-text mr-3"></i>
                                        Показать на карте
                                    </a>                                    
                                </div>
                            </div>

                            <div className={"col-12 col-mg-6 col-xl-6"}>
                                <div className={"shadow rounded bg-white  p-3 my-3"}>
                                    <h4>
                                        Клиника Дипплекс Худякова, 13
                                    </h4>
                                    <hr/>
                                    <div>
                                        <i className="fas fa-phone dnk-blue-text mr-3"></i>
                                        8 (351) 214-23-23
                                    </div>
                                    <hr/>
                                    <a className={"d-block"} href={"http://мояноваяжизнь.рф"} target={"_blank"}>
                                        <i className="fas fa-globe dnk-blue-text mr-3"></i>
                                        мояноваяжизнь.рф
                                    </a>
                                    <hr/>                                    
                                    <a className={"d-block"} href={"https://go.2gis.com/4cq1t"} target={"_blank"}>
                                        <i className="fas fa-map-marker-alt dnk-blue-text mr-3"></i>
                                        Показать на карте
                                    </a>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
}