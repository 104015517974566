import React, { Component } from 'react';
import Specialities from '../components/online/Specialities';
import Speciality from '../components/online/Speciality';
import Office from '../components/online/Office';
import Doctor from '../components/online/Doctor1';

export default class OnlineScreen  extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            step: 1,
            specName: "",
            specId: "",
            filialId: "",
            filialName: "",
            doc: {}
        }
    }

    selectSpec = (specId, specName) => {
        this.setState({
            step: 2,
            specName: specName,
            specId: specId
        });
    };

    selectFilial = (filial) => {
        this.setState({
            step: 3,
            filial: filial
        });
    };

    selectDoc = (doc) => {
        this.setState({
            step: 4,
            doc: doc
        });
    };

    render(){
        //console.log(this.state);
        if(this.state.step == 1)
            return <Specialities selectSpec={this.selectSpec} />;

        if(this.state.step == 2)
            return <Speciality
                selectSpec={this.selectSpec}
                specName={this.state.specName}
                specId={this.state.specId}
                selectFilial={this.selectFilial}
                back={() => this.setState({step: 1})}
            />;

        if(this.state.step == 3){
            return <Office
                filial={this.state.filial}
                selectDoc={this.selectDoc}
                back={() => this.setState({step: 2})}
            />
        }

        if(this.state.step == 4){
            return <Doctor
                filial={this.state.filial}
                spec={this.state.specId}
                doc={this.state.doc}
                back={() => this.setState({step: 3})}
            />
        }
    }
}