import React, { Component } from 'react';

export default class MedKarta extends Component {
    constructor() {
        super(...arguments);
        this.state = {

        };
    }

    render() {
        return (
            <div className={""}>
                <h3 className={"text-left"}>Медицинская карта</h3>
                <div className={"row mt-3"}>
                    <div className={"col-xl-4 col-md-6 my-2"}>
                        <div
                            className={"cursor-pointer justify-content-between bg-white rounded shadow p-3 text-center row align-items-center"}
                            onClick={() => this.props.history.push("/medkarta/zapisi")}
                        >
                            <div className={"d-flex align-items-center"}>
                                <i className="far fa-2x fa-calendar-alt mr-3"></i>
                                Записи на приём
                            </div>
                            <i className="fas fa-chevron-right dnk-blue-text fa-2x"></i>
                        </div>

                    </div>

                    <div className={"col-xl-4 col-md-6 my-2"}>
                        <div
                            className={"cursor-pointer bg-white justify-content-between rounded shadow p-3 text-center row align-items-center"}
                            onClick={() => this.props.history.push("/medkarta/priemy")}
                        >
                            <div className={"d-flex align-items-center"}>
                                <i className="far fa-2x fa-calendar-check mr-3"></i>
                                Прошедшие приёмы
                            </div>
                            <i className="fas fa-chevron-right dnk-blue-text fa-2x"></i>
                        </div>
                    </div>

                    <div className={"col-xl-4 col-md-6 my-2"}>
                        <div
                            className={"cursor-pointer bg-white justify-content-between rounded shadow p-3 text-center row align-items-center"}
                            onClick={() => this.props.history.push("/medkarta/analizy")}
                        >
                            <div className={"d-flex align-items-center"}>
                                <i className="fas fa-2x fa-microscope mr-3"></i>
                                Результаты анализов
                            </div>
                            <i className="fas fa-chevron-right dnk-blue-text fa-2x"></i>
                        </div>
                    </div>

                    <div className={"col-xl-4 col-md-6 my-2"}>
                        <div
                            className={"cursor-pointer bg-white justify-content-between rounded shadow p-3 text-center row align-items-center"}
                            onClick={() => this.props.history.push("/medkarta/naznacheniya")}
                        >
                            <div className={"d-flex align-items-center"}>
                                <i className="fas fa-2x fa-file-invoice mr-3"></i>
                                Назначения
                            </div>
                            <i className="fas fa-chevron-right dnk-blue-text fa-2x"></i>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
