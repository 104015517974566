import React, { Component } from 'react';
import axios from 'axios';
import {Redirect, Route, withRouter} from "react-router-dom";
import LoadingIndicator from "../components/LoadingIndicator";
import dateFormat from 'dateformat';

class RegScreen extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            tel: (this.props.match.params.tel) ? this.props.match.params.tel : "8",
            f: "",
            i: "",
            o: "",
            pass: "",
            uid: "",
            step: 1,
            day: "",
            days: [],
            month: "",
            year: "",
            date: false,
            loading: false,
            auth: false,
            error: false,
            errorCode: 0,
            resetPswd: false,
            reg: false
        }
    }

    telChange = (event) => {
        const target = event.target;
        let value = target.value.replace(/\D+/gi, "");

        if(value.length === 0){
            value = "8";
        }
        else {
            value = "8" + value.substr(1);
        }
        if(value.length === 11){
            //this.checkProfiles(value);
        }
        else {
            this.setState({
                profile: "",
                profiles: [],
                error: false,
                pass: "",
                resetPswd: false
            });
        }

        this.setState({
            tel: value
        });
    };

    resetPswd = () => {
        this.setState({
            error: false
        });

        let data = "uguid=" + this.state.uid;
        axios.post('https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/account/resetPswd', data)
            .then((res) => {
                this.setState({
                    resetPswd: true
                });
            })
            .catch(function (err) {
                console.log("Ошибка сброса пароля: "+err.message);
            });
    };

    register = () => {
        this.setState({loading: true});
        //let day = (this.state.date.getDate() < 10) ? ( "0" + this.state.date.getDate()) : this.state.date.getDate();
        //let month = ((this.state.date.getMonth() + 1) < 10) ? ( "0" + (this.state.date.getMonth() + 1)) : (this.state.date.getMonth() + 1);

        //let date = this.state.date.getFullYear() + "-" + month + "-" + day;
        //let date = this.state.year + "-" + this.state.month + "-" + this.state.day;
        let data = "f="+this.state.f + "&i=" + this.state.i + "&o=" + this.state.o + "&d=" + this.state.date + "&t=" + this.state.tel;

        axios({
            method: 'post',
            url: 'https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/reg',
            data: data,
            withCredentials: true
        }).then((res) => {
            if(res.data.error){
                this.setState({
                    loading: false,
                    error: true,
                    errorCode: res.data.errorCode,
                    errorMsg: res.data.message
                });
            }
            else {
                this.setState({
                    loading: false,
                    error: false,
                    errorCode: res.data.errorCode,
                    uid: res.data.uid
                });
            }
        })
            .catch((err) => {
                this.setState({loading: false, error: true, errorCode: 0, errorMsg: "Ошибка при попытке регистрации" });
            });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.register();
    };

    handleDateChange = (e) => {
        let obj = {...this.state};
        let val = e.target.value.replace(/\D+/gi, "");

        if(e.target.name === "day"){
            val = val.substr(0,2);
            if(val.length === 2 && +val < 10) val = "0" + +val;
            if(+val > 31) val = 31;
        }

        if(e.target.name === "month"){
            if(val.length === 2 && +val < 10) val = "0" + +val;
            if(val > 12) val = 12;
        }

        if(e.target.name === "year") {
            val = val.substr(0, 4);
        }

        obj[e.target.name] = val;

        if(obj.year.length === 4 && obj.month.length === 2 && obj.day.length === 2){
            let now = new Date();
            let date = new Date(obj.year + "-" + obj.month + "-" + obj.day);

            if(date > now){
                obj.year = "";
            }
        }

        this.setState(obj);
    };

    render() {

        //Такой пользователь уже существует в базе
        if(this.state.error)
            return (
                <div className={"my-5 login-form"}>
                    <h1 className={"text-center mb-4"}>
                        Регистрация в личном кабинете
                    </h1>
                    <div className={"bg-white rounded shadow p-3 p-lg-5"}>
                        {
                            (this.state.errorCode === 1) &&
                            <div>
                                <h4 className={"mb-3"}>
                                    Пользователь с такими данными уже был ранее зарегистрирован в системе.
                                </h4>
                                <p>
                                    Вы можете вернуться к форме входа, чтобы сбросить пароль. Новый пароль придет по СМС на указанный вами номер телефона.
                                </p>
                            </div>
                        }
                        {
                            (this.state.errorCode === 0) &&
                            <h4 className={"mb-3"}>
                                При попытке регистрации возникла ошибка. Попробуйте ещё раз позднее.
                            </h4>
                        }
                        <div className={"my-5 text-center"}>
                            <button
                                className={"btn dnk-pink btn-lg rounded"}
                                onClick={() => this.props.history.push('/login')}
                            >
                                Войти
                            </button>
                        </div>
                    </div>
                </div>
            );

        //Успешно зарегистрировались и получили ID пользователя
        if (this.state.uid.length > 0) {
            return (
                <div className={"my-5 login-form"}>
                    <h1 className={"text-center mb-4"}>
                        Регистрация в личном кабинете
                    </h1>
                    <div className={"bg-white rounded shadow p-3 p-lg-5"}>
                        <h4 className={"mb-3"}>
                            Вы успешно зарегистрировались
                        </h4>
                        <p>
                            На номер телефона, который вы указали при регистрации, должно прийти СМС с паролем для
                            входа.
                        </p>
                        <div className={"my-5 text-center"}>
                            <button
                                className={"btn dnk-pink btn-lg rounded"}
                                onClick={() => this.props.history.push('/login')}
                            >
                                Войти
                            </button>
                        </div>
                        {
                            (this.state.resetPswd) ? (
                                <div className={"text-center"}>
                                    <p>СМС с новым паролем отправлено.</p>
                                </div>
                            ) : (
                                <div className={"text-center"}>
                                    <button
                                        className={"btn btn-link"}
                                        onClick={this.resetPswd}
                                    >
                                        Отправить пароль ещё раз
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>
            )
        }

        // дефолтная форма регистрации
        return (
            <div className={"my-5 login-form"}>
                <h1 className={"text-center mb-4"}>
                    Регистрация в личном кабинете
                </h1>
                <div className="">
                    <div className="mx-4">
                        <div className={"flip-card"}>
                            <div className={(this.state.step > 100) ? "flip-card-inner flipped" : "flip-card-inner"}>
                                <div className="blue-form dnk-blue-pattern shadow py-3 flip-card-front">
                                    <div className={"mb-3"}>Все поля обязательны для заполнения</div>
                                    <form autoComplete={"none"} onSubmit={this.handleSubmit}>
                                        <div className="form-group input-group-lg text-left">
                                            <label>Номер телефона</label>
                                            <input
                                                autoComplete={"none"}
                                                type="text"
                                                required={true}
                                                disabled={true}
                                                className={"form-control"}
                                                maxLength={11}
                                                name={"tel"}
                                                placeholder="8"
                                                value={this.state.tel}
                                                onChange={(e) => this.setState({tel: e.target.value.replace(/\D+/gi, "")})}
                                            />
                                        </div>
                                        <div className="form-group input-group-lg text-left">
                                            <label>Фамилия</label>
                                            <input
                                                required={true}
                                                autoComplete={"none"}
                                                type="text"
                                                className={"form-control"}
                                                name={"f"}
                                                value={this.state.f}
                                                onChange={(e) => this.setState({f: e.target.value.replace(/[^а-я]+/gi, "")})}
                                            />
                                        </div>
                                        <div className="form-group input-group-lg text-left">
                                            <label>Имя</label>
                                            <input
                                                required={true}
                                                autoComplete={"off"}
                                                type="text"
                                                className={"form-control"}
                                                name={"i"}
                                                value={this.state.i}
                                                onChange={(e) => this.setState({i: e.target.value.replace(/[^а-я]+/gi, "")})}
                                            />
                                        </div>
                                        <div className="form-group input-group-lg text-left">
                                            <label>Отчество</label>
                                            <input
                                                required={true}
                                                autoComplete={"off"}
                                                type="text"
                                                className={"form-control"}
                                                name={"o"}
                                                value={this.state.o}
                                                onChange={(e) => this.setState({o: e.target.value.replace(/[^а-я]+/gi, "")})}
                                            />
                                        </div>
                                        <div className="form-group input-group-lg text-left">
                                            <label>Дата рождения</label>
                                            <div className="d-flex">
                                                <input
                                                    type="date"
                                                    max={dateFormat(new Date(), "yyyy-mm-dd")}
                                                    min={"1900-01-01"}
                                                    required={true}
                                                    className={"form-control w-100"}
                                                    value={this.state.date}
                                                    onChange={(e) => this.setState({date: e.target.value}) }
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group input-group-lg text-left">
                                            <div className="d-flex">
                                                <input type="checkbox" className="ml-0 mr-3 mt-2" id="exampleCheck1" />
                                                <label className="form-check-label" htmlFor="exampleCheck1" style={{ userSelect: "none" }}>
                                                    Я согласен(на) на обработку персональных данных в соответствии с
                                                    <a className="pl-2" href="https://dnk174.ru/o-klinike/politika-obrabotki-personalnykh-dannykh/" target="_blank">
                                                        Положением об обработке персональных данных.
                                                    </a>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="form-group input-group-lg">
                                            {
                                                (this.state.loading) ? (
                                                    <div className={"bg-white p-2 rounded"}>
                                                        <LoadingIndicator small={true}/>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div>
                                                            <button
                                                                type={"submit"}
                                                                className={"btn dnk-pink rounded mt-4"}
                                                            >
                                                                Зарегистрироваться
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </form>
                                    <div>
                                        <button
                                            onClick={() => this.props.history.push('/login')}
                                            className={"btn btn-light rounded mt-4 mr-3"}
                                        >
                                            <i className="fas fa-chevron-left mr-2"></i>
                                            Назад
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(RegScreen);