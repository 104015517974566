import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from "axios";
import LoadingIndicator from "../components/LoadingIndicator";
import NearestApp from "../components/home/NearestApp";
import Socials from "../components/home/Socials";

export default class HomeScreen  extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            user: {},
            loading: true,
            redirect: false,
            bonus: 0,
            showBonuses: true
        }
    }

    componentWillMount() {
        const user = JSON.parse(sessionStorage.getItem('userData'));

        /*
        let showBonuses = false;

        if(
            sessionStorage.userProfile === "40ba2301-ed95-de11-aee8-00218512fe74"
            || sessionStorage.userProfile === "919a4d88-c3ba-e611-b256-001517fb7538"
            || sessionStorage.userProfile === "c8376ddd-4ce4-4ccb-86a2-cb2908ee158d"
            || sessionStorage.userProfile === "ac7d2893-af10-11e7-a0ec-bff636bd7176"
        ){
            showBonuses = true;
        }
         */

        if(user !== null){
            this.setState({
                user: user,
                redirect: false,
                //showBonuses: showBonuses
            });
            this.getBonuses();
        }
        else {
            this.setState({redirect: true});
        }
    }

    getBonuses = () => {
        axios({
            method: 'get',
            url: 'https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/get/bonus_history',
            withCredentials: true
        }).then((res) => {
            if(res.data['ИсторияБонусов'].length > 0){
                let b = res.data['ИсторияБонусов'].pop();
                this.setState({
                    loading: false,
                    bonus: b['Остаток']
                });
                //console.log(this.state.user);
            }
            else{
                this.setState({
                    loading: false,
                    bonus: 0
                });
            }
        })
            .catch( (err) => {
                this.setState({
                    loading: false,
                    error: Math.random(),
                    errorText: "Ошибка подключения к серверу. Обновите страницу и попробуйте ещё раз."
                });
            })
    };

    render(){

        if(this.state.redirect)
            return <Redirect to={"/login"} />;

        return(
            <div className={""}>
                <div className={"pb-5"}>

                    {
                        ( this.state.showBonuses ) ?
                            <div className={"mx-auto col-12 col-md-8 col-lg-6 mt-5"}>
                                <div className="rounded bg-white shadow p-4 text-center">
                                    <h3>
                                        {this.state.user['ФИО']}
                                    </h3>

                                    {(this.state.loading) ? (
                                        <LoadingIndicator />
                                    ) : (
                                        <div>
                                            <div className={"mt-3 display-4"}>
                                                {this.state.bonus}
                                            </div>
                                            <h5 className={""}>
                                                бонусов
                                            </h5>
                                        </div>
                                    )}

                                    <hr/>

                                    <h5>
                                        Статус: {this.state.user['Статус']}
                                    </h5>
                                    <Link
                                        to={'/bonuses'}
                                        className={"btn btn-outline-secondary rounded mt-3"}
                                    >
                                        Программа лояльности
                                    </Link>
                                </div>
                            </div> : null
                    }

                    <div className={"mx-auto col-12 col-md-8 col-lg-6 mt-5"}>
                        <div className="rounded bg-white shadow p-4 text-center">
                            <h3>
                                Сдайте анализ на коронавирус в наших клиниках!
                            </h3>
                            <p className={"pt-4"}>
                                Стоимость 2150 рублей.
                            </p>
                            <div>
                                <h4>Запишитесь online:</h4>
                                <ol className={"pl-2 text-left"}>
                                    <li>
                                        Выберите специальность "Процедурный кабинет" и адрес клиники.
                                    </li>
                                    <li>
                                        В процедурном COVID окройте расписание и выберите нужную услугу в выпадающем меню: антитела (кровь) или ПЦР (мазок).
                                    </li>
                                    <li>
                                        Выберите дату и время для сдачи анализа.
                                    </li>
                                    <li>
                                        Оплатите online через мобильное приложение - Ваша экономия составит 160 рублей.
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    <div className={"col-12 col-lg-6 col-md-8 mx-auto mt-5"}>
                        <div className={"dnk-pink p-3 rounded shadow text-center"}>
                            <h5>
                                Подберите удобное время для приёма
                            </h5>
                            <Link
                                to={"/online"}
                                className={"btn btn-light rounded mt-3"}
                            >
                                Записаться на приём
                            </Link>

                        </div>
                    </div>

                    <NearestApp />

                    <Socials />

                </div>
            </div>
        );
    }
}