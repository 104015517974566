import React, { Component } from 'react';
import axios from "axios";
import LoadingIndicator from "../components/LoadingIndicator";

export default class OtzyvScreen extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            loading: false,
            saveSuccess: false,
            saveError: false,
            text: "",
        }
    }

    send = () => {
        this.setState({loading: true});
        let data = "review=" + this.state.text;

        axios("https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/set/review", {
            method: "post",
            data: data,
            withCredentials: true
        })
            .then((res) => {
                res.data.success ?
                    this.setState({
                        saveSuccess: true,
                        saveError: false,
                        reviewError: false,
                        loading: false
                    })
                    :
                    this.setState({ saveError: true, loading: false });
            })
            .catch(function (err) {
                //console.log("Ошибка Send Review: "+err.message);
                this.setState({ saveError: true });
            });
    };

    render(){
         let cont = (
             <div>
                 <div className="form-group input-group-lg">
                     <textarea
                         type="text"
                         rows={10}
                         className={"form-control"}
                         name={"text"}
                         value={this.state.text}
                         onChange={(e) => this.setState({text: e.target.value})}
                         required={true}
                     />
                 </div>
                 <div className="form-group input-group-lg text-center">
                     <button
                         type="button"
                         className="btn rounded dnk-pink my-2"
                         disabled={(this.state.text.length > 10) ? false : true}
                         onClick={this.send}
                     >
                         Отправить отзыв
                     </button>
                 </div>
             </div>
         );

         if(this.state.loading)
             cont = (
                 <div className={"text-center"}>
                     <LoadingIndicator />
                 </div>
             );

        if(this.state.saveError)
            cont = (
                <div className={"text-center dnk-pink p-4 rounded mt-3"}>
                    <h4>Возникла ошибка при отправке отзыва. Попробуйте ещё раз позднее.</h4>
                </div>
            );

        if(this.state.saveSuccess)
            cont = (
                <div className={"text-center dnk-blue p-4 rounded mt-3"}>
                    <h4>Спасибо! Ваш отзыв успешно отправлен.</h4>
                </div>
            );


        return(
            <div className={"mb-5"}>
                <h3 className={"mb-3"}>
                    Отправить отзыв
                </h3>
                <div className={"row"}>
                    <div className={"col-12 col-md-4 col-lg-3"}>
                        <img src="/img/OB.jpg" alt="" className={"img-fluid rounded p-3"} />
                        <div className={"p-3"}>
                            <p>
                                Берестова Ольга Вячеславовна, кандидат медицинских наук.
                            </p>
                            <p>
                                Генеральный директор группы компаний ДНК Клиника, Дипплекс, Забота.
                            </p>
                        </div>
                    </div>
                    <div className={"col-12 col-md-8"}>
                        <div className={"shadow rounded bg-white p-3 p-lg-5 "}>
                            <p className={"text-justify"}>
                                Я благодарю Вас за выбор Клиники. В основу своей работы я всегда закладываю заботу о Пациентах и требую того же от своих сотрудников. Каждый отзыв я читаю лично, потому что только мнение Пациентов является истинной оценкой нашей работы.
                            </p>
                            <p className={"text-justify"}>
                                Если Вам что-то не понравилось, это останется между нами. Но Клиника получит шанс стать лучше. Меры по Вашему обращению обязательно будут приняты.
                            </p>
                            <p className={"text-justify"}>
                                Если Вы довольны работой Клиники, напишите об этом. Врачам обычно благодарны молча, а им важно понимать, что они все сделали правильно.
                            </p>
                            <div>
                                <label style={{ fontFamily: "CeraMedium" }}>Ваш отзыв</label>
                                {cont}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}