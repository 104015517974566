import React, { Component } from 'react';
import axios from "axios";
import LoadingIndicator from "../LoadingIndicator";
import { withRouter } from 'react-router-dom';

class NearestApp extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            app: [],
            loading: true
        }
    }

    componentDidMount() {
        axios({
            method: 'get',
            url: 'https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/get/appointments',
            withCredentials: true
        }).then((res) => {
            this.getApp(res.data);
        })
            .catch(function (err) {
                console.log("Ошибка: "+err.message);
            });
    }

    getApp = (apps) => {
        let nearestApp = false;

        if(apps.length > 0){
            while(apps.length > 0){
                let a = apps.pop();
                let now = new Date();
                let data = new Date(a['Дата']);

                if(now < data){
                    nearestApp = {};
                    nearestApp.date = a['Дата'].substr(8,2) + "." + a['Дата'].substr(5,2)  + "." + a['Дата'].substr(0,4) + " в " + a['Дата'].substr(11,5)
                    nearestApp.doc = a['Специалист'];
                    nearestApp.spec = a['Специальность'];
                    nearestApp.addr = a['Офис'];
                    break;
                }
            }
        }

        this.setState({
            app: nearestApp,
            loading: false
        });
    };

    render(){
        if(this.state.loading)
            return (
                <div className={"col-12 col-lg-6 col-md-8 mx-auto mt-5 text-center"}>
                    <h3 className={"text-center"}>
                        Ближайшая запись на приём
                    </h3>
                    <LoadingIndicator />
                </div>
            );

        if(this.state.app)
            return (
                <div className={"col-12 col-lg-6 col-md-8 mx-auto mt-5"}>
                    <h3 className={"text-center"}>
                        Ближайшая запись на приём
                    </h3>
                    <div
                        className={"rounded shadow p-4 mt-3 bg-white cursor-pointer"}
                        onClick={() => this.props.history.push("/medkarta/zapisi")}
                    >
                        <h5>
                            <i className="fas fa-clock mr-3"></i>
                            {this.state.app.date}
                        </h5>
                        <h5>
                            <i className="fas fa-map-marker-alt mr-3"></i>
                            {this.state.app.addr}
                        </h5>
                        <h5>
                            <i className="fas fa-user-md mr-3"></i>
                            {this.state.app.doc} ({this.state.app.spec})
                        </h5>
                    </div>
                </div>
            );
        else {
            return (
                <div className={"col-12 col-lg-6 col-md-8 mx-auto mt-5"}>
                    <h3 className={"text-center"}>
                        Ближайшая запись на приём
                    </h3>
                    <div className={"rounded shadow p-3 mt-3 bg-white text-center"}>
                        Предстоящих записей на приём не найдено
                    </div>
                </div>
            );
        }
    }
}

export default withRouter(NearestApp);