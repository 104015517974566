import React, { Component } from 'react';

export default class AnalizRow extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            hide: true
        }
    }

    componentDidMount() {
        var timer = setTimeout(()=> this.setState({hide: false}), (100 * this.props.i));
    }

    render() {
        if(this.state.hide)
            return (
                <div className={"line"}></div>
            );
        else
        return (
            <div className={"line line-show"}>
                <div className={"px-3 py-1 row my-3 shadow rounded bg-white align-items-center"}>
                    <div className={"col-lg-3 col-xl-2"}>
                        <a href={"https://мойличныйдоктор.рф/file/" + this.props.link} target={"_blank"} className={"download-btn d-flex align-items-center"}>
                            <i className="fas fa-cloud-download-alt fa-2x mr-3"></i>
                            <div>
                                Скачать
                            </div>
                        </a>

                    </div>
                    <div className={"col-lg-3 col-xl-2"}>
                        <div className={"d-flex align-items-center my-2 my-lg-3"}>
                            <i className="far fa-calendar-check fa-2x mr-3"></i>
                            <div>
                                {this.props.date.substr(8,2) + "." + this.props.date.substr(5,2) + "." + this.props.date.substr(0,4) + " в " + this.props.date.substr(11,5)}
                            </div>
                        </div>
                    </div>
                    <div className={"col-lg-6"}>
                        <div className={"d-flex align-items-center my-2 my-lg-3"}>
                            <i className="fas fa-microscope fa-2x mr-3"></i>
                            {this.props.name}
                        </div>
                    </div>

                </div>
            </div>

        );

    }
}

