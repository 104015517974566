import React, { Component } from 'react';

export default class FreeTimeRow extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {

        }
    }

    render(){
        return(
            <div className={"mb-4"}>
                <div className={"row align-items-center"}>
                    <p className={"mr-3 my-0"}>
                        <b>
                            {this.props.item.day}
                        </b>
                    </p>
                    <div className={"w-100"}>
                        <div>
                        {
                            (this.props.item.time.length > 0) ?
                                this.props.item.time.map((u,i)=>{
                                    return (
                                        <button
                                            className={"btn rounded shadow dnk-blue px-4 m-2"}
                                            style={{width: 90}}
                                            key={i}
                                            onClick={() => this.props.setZapis(this.props.item.day, u)}
                                        >
                                            {u}
                                        </button>
                                    )
                                })
                             : null
                        }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}