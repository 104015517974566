import React, { Component } from 'react';
import axios from "axios";
import LoadingIndicator from '../LoadingIndicator';

export default class PriemRow extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            warning: false,
            deleted: false,
            loading: false
        }
    }

    processPayment = () => {

        if(this.state.zapis && this.state.zapis.length > 0){

            this.setState({
                step: 4,
                loading: true
            });

            let data = {
                amount: this.state.services[this.state.service]['Цена'],
                doc: this.props.doc['Фамилия'] + " " + this.props.doc['Имя'] + " " + this.props.doc['Отчество'],
                zapis: this.state.zapis, //передаем для последующей простановки скидки, если оплата будет успешной,
                discount: this.state.services[this.state.service]['Скидка'],
                msg: this.state.saveSuccessMessage
            };

            axios("https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/payments/geturl/", {
                method: "post",
                data: data,
                withCredentials: true
            })
                .then((res) => {
                    if(res.data.error){
                        console.log(res.data);
                    }
                    else {
                        this.setState({
                            paymentUrl: res.data.formUrl,
                            loading: false
                        });
                    }
                })
                .catch( (err) => {
                    this.setState({
                        loading: false,
                        auth: false,
                        error: Math.random(),
                        errorText: "Ошибка подключения к серверу. Обновите страницу и попробуйте ещё раз."
                    });
                });
        }
        else {
            this.setState({
                loading: false,
                error: Math.random(),
                errorText: "Ошибка при попытке оплаты."
            });
        }
    };

    /*
    cancelZapis = () => {
        this.setState({loading: true});

        axios({
            method: 'get',
            url: 'https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/set/cancelZapis/' + this.props.id,
            withCredentials: true
        }).then((res) => {
            console.log(res.data);
            this.props.refresh();
        })
            .catch( (err) => {
                this.setState({
                    loading: false,
                    error: Math.random(),
                    errorText: "Ошибка подключения к серверу. Обновите страницу и попробуйте ещё раз."
                });
            })
    };

     */

    cancelZapis = () => {
        this.setState({loading: true});

        let data = {
            phone: sessionStorage.userPhone,
            "Дата": this.props.date,
            "Специалист": this.props.doc
        };

        axios({
            method: 'post',
            url: 'https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/set/cancelZapisToCallCenter/',
            data: data,
            withCredentials: true
        }).then((res) => {
            //console.log(res.data);
            //this.props.refresh();
            this.setState({ deleted: true });
        })
            .catch( (err) => {
                this.setState({
                    loading: false,
                    deleted: false,
                    error: Math.random(),
                    errorText: "Ошибка подключения к серверу. Обновите страницу и попробуйте ещё раз."
                });
            })
    };

    render() {

        console.log(this.props);

        if(this.state.deleted)
            return (
                <div className={"row p-3 my-3 shadow d-flex align-items-center justify-content-center rounded bg-white"}>
                    <b>
                        Спасибо! Мы получили Ваше сообщение. Менеджер свяжется с Вами в течение 5 минут или в ближайшее рабочее время.
                    </b>
                </div>
            );

        if(this.state.loading) {
            return (
                <div className={"row px-3 py-2 my-3 shadow d-flex align-items-center justify-content-center rounded bg-white"}>
                    <LoadingIndicator small={true} />
                </div>
            );
        }

        return (
            <div className="position-relative px-3 py-2 my-3 shadow rounded bg-white overflow-hidden">
                <div className={"row px-3 py-2 priem-row" +  ((this.state.warning) ? " priem-row-filled" : "")}>
                    <div className={"col-12 col-lg-6 d-flex align-items-center my-2 my-lg-3 px-2"}>
                        <h4 className={"text-white"}>
                            Вы уверены, что хотите отменить/перенести запись?
                        </h4>
                    </div>
                    <div className={"col-12 col-lg-6 d-flex align-items-center justify-content-center my-2 my-lg-3 px-2"}>
                        <button
                            className={"btn btn-outline-light rounded d-flex align-items-center mr-5 px-4"}
                            onClick={this.cancelZapis}
                        >
                            Да
                        </button>
                        <button
                            className={"btn btn-outline-light rounded d-flex align-items-center px-4"}
                            onClick={()=> this.setState({warning: false})}
                        >
                            Нет
                        </button>
                    </div>
                </div>
            <div className={"row"}>
                <div className={"col-12 col-lg-3 col-xl-2 d-flex align-items-center my-2 my-lg-3 px-2"}>
                    {
                        (this.state.deleted) ? (
                            <div className={"d-flex align-items-center dnk-pink-text"}>
                                <i className="far fa-times-circle fa-2x mr-3"></i>
                                <div>
                                    <div>
                                        {this.props.date.substr(8,2) + "." + this.props.date.substr(5,2) + "." + this.props.date.substr(0,4) + " в " + this.props.date.substr(11,5)}
                                    </div>
                                    <div>
                                        Отменён
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={"d-flex align-items-center"}>
                                <i className="far fa-calendar-alt fa-2x mr-3"></i>
                                <div>
                                    {this.props.date.substr(8,2) + "." + this.props.date.substr(5,2) + "." + this.props.date.substr(0,4) + " в " + this.props.date.substr(11,5)}
                                </div>
                            </div>
                        )
                    }

                </div>
                <div className={"col-12 col-lg-2 col-xl-3 d-flex align-items-center my-2 my-lg-3 px-2"}>
                    <i className="fas fa-map-marker-alt fa-2x mr-3"></i>
                    {this.props.office}
                </div>
                <div className={"col-12 col-lg-5 d-flex align-items-center my-2 my-lg-3 px-2"}>
                    <div style={{width: 40}} className={"mr-3"}>
                        <img src={"/img/vrachi/" + this.props.doc + ".png"} height={"50"} className={"mr-3"} alt=""/>
                    </div>
                    <div>
                        <b>{this.props.doc}</b>
                        <br />
                        {this.props.spec}
                    </div>
                </div>
                <div className={"col-12 col-lg-1 col-xl-2 d-flex align-items-center justify-content-end my-2 my-lg-3 px-2"}>
                    {
                        (this.props.id && !this.state.deleted) ? (
                            <div>
                                <button
                                    className={"btn rounded btn-outline-secondary"}
                                    onClick={()=> this.setState({warning: true})}
                                >
                                    Отменить
                                </button>
                                <button
                                    type="button"
                                    className="btn d-none rounded dnk-blue my-3"
                                    onClick={ this.processPayment }
                                >
                                    Оплатить онлайн
                                </button>
                            </div>
                        ) : null
                    }
                </div>
            </div>
            </div>
        );

    }
}

