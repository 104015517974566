import React, { Component } from 'react';

export default class LoadingIndicator extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {

        }
    }

    render() {
        return (
            <img
                src="/img/loader.svg"
                height={(this.props.small) ? 50 : 150}
                alt="Загрузка"
                style={{transform: "rotate(90deg)"}}
            />
        );
    }
}

/*
<!-- div className="spinner-grow dnk-blue-text" role="status" style={{width: "3rem", height: "3rem"}}>
                    <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow dnk-pink-text" role="status" style={{width: "3rem", height: "3rem"}}>
                    <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow dnk-blue-text" role="status" style={{width: "3rem", height: "3rem"}}>
                    <span className="sr-only">Loading...</span>
                </div -->
 */