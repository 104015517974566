import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class Socials extends Component {
    constructor()
    {
        super(...arguments);
    }

    render() {
            return (
                <div className={"col-12 col-lg-6 col-md-8 mx-auto mt-5"}>
                    <h3 className={"text-center"}>
                        Присоединяйтесь!
                    </h3>
                    <div className={"rounded shadow p-4 mt-3 bg-white cursor-pointer"}>
                        <div className={"row"}>
                            <div className={"col-4 text-center"}>
                                <a href="https://www.instagram.com/dnk_klinika/" target="_blank">
                                    <i className="fab fa-instagram fa-4x" style={{ color: "#E72A65" }}></i>
                                </a>
                            </div>
                            <div className={"col-4 text-center"}>
                                <a href="https://vk.com/dnk74" target="_blank">
                                    <i className="fab fa-vk fa-4x" style={{ color: "#33B5CD" }}></i>
                                </a>
                            </div>
                            <div className={"col-4 text-center"}>
                                <a href="https://www.youtube.com/channel/UCQlRouGflyMDQMtIjgiVijQ" target="_blank">
                                    <i className="fab fa-youtube fa-4x" style={{ color: "#ff1600" }}></i>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            );
    }
}

export default withRouter(Socials);