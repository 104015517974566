import React, { Component } from 'react';
import PriemRow from "./PriemRow";

export default class FuturePriemy extends Component {
    constructor() {
        super(...arguments);
    }

    render(){
        var count = 0;
        var priemy = null;

        if(this.props.priemy.length > 0){
            priemy = this.props.priemy.map((u,i) => {
                var date = new Date(u['Дата']);
                var now = new Date();
                //console.log(date);
                //console.log(now);
                if(date > now){
                    //console.log(date);
                    count++;
                    return (
                        <PriemRow
                            key={i}
                            date={u['Дата']}
                            doc={u['Специалист']}
                            office={u['Офис']}
                            spec={u['Специальность']}
                            id={u['ЗаписьНаПрием']}
                            refresh={this.props.refresh}
                        />
                    );
                }
                else return null;
            });
        }

        if(count > 0)
            return (
                <div>
                    {priemy.reverse()}
                </div>
            );
        else return (
            <div className={"mt-5 d-flex text-center flex-column justify-content-center align-items-center"}>
                <p><i className="fas fa-search fa-5x mb-3"></i></p>
                <h3>У вас пока нет запланированных приёмов</h3>
            </div>
        );
    }
}



