import {Redirect, Route} from "react-router-dom";
import React, { Component } from "react";
import axios from 'axios';

export default class SecretRoute  extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            auth: false,
            loading: true
        }
    }

    componentDidMount() {
        this.checkAuth();
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.checkAuth();
    }

    checkAuth = () => {
        axios({
            method: 'get',
            url: 'https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/account/checkAuth',
            withCredentials: true
        }).then((res) => {
                //console.log("AUTH: " + res.data);
                this.setState({
                    auth: res.data,
                    loading: false
                });
            })
            .catch( (err) => {
                this.setState({
                    loading: false,
                    auth: false,
                    error: Math.random(),
                    errorText: "Ошибка подключения к серверу. Обновите страницу и попробуйте ещё раз."
                });
            })
    };

    render() {
        if(this.state.loading) return null;
        else {
            if(this.state.auth){
                let Component = this.props.component;
                return (
                    <div className={"content"}>
                        <Route {...this.props} render={(props) => (<Component {...props} />)} />
                    </div>
                    );
            }
            else {
                return <Redirect to='/login' /> ;
            }
        }
    }
}