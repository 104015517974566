import React, { Component } from 'react';
import { NavLink, Link} from 'react-router-dom';
import config from '../config';

export default class Drawer extends Component {
   render() {
        return (
            <div id={"drawer"} className={"shadow  py-3"}>
                <div className={"drawer-inner"}>
                    <div>
                    {
                        (this.props.fio.length > 0) ? (
                            <div className={"profile-name mx-auto"}>
                                <span className={""}>{this.props.fio}</span>
                                <Link to={"/logout"}
                                      className={"btn m-0 ml-2 p-0"}
                                >
                                    <i className="fas fa-sign-out-alt fa-2x text-white"></i>
                                </Link>
                            </div>
                        ) : (
                            <div className={"mx-auto hideable"}>
                                <img src="/img/logolk.svg" width="30" height="30" alt="" style={{marginTop: -8, marginRight: 10, marginLeft: "0.6rem"}} />
                                <span className={"drawer-label logo-label"}>Личный кабинет</span>
                            </div>
                        )
                    }

                    <hr className={"mx-3 mt-3 mb-1"} />
                        <div>
                            {
                                (this.props.fio.length > 0) &&
                                <>
                                    <NavLink exact to="/" className={"drawer-item  hideable"} activeClassName="drawer-item-selected">
                                        <i className="fas fa-home mr-2"></i>
                                        <span className={"drawer-label"}>
                                    Главная
                                </span>
                                    </NavLink>

                                    <NavLink to="/medkarta" className={"drawer-item  hideable"} activeClassName="drawer-item-selected">
                                        <i className="fas fa-address-card mr-2"></i>
                                        <span className={"drawer-label"}>
                                            Медицинская карта
                                        </span>
                                    </NavLink>

                                    <NavLink to="/online" className={"drawer-item  hideable"} activeClassName="drawer-item-selected">
                                        <i className="fas fa-user-md mr-2"></i>
                                        <span className={"drawer-label"}>
                                    Онлайн-расписание
                                </span>
                                    </NavLink>

                                    <NavLink to="/notifications" className={"drawer-item  hideable"} activeClassName="drawer-item-selected">
                                        <i className="fas fa-bell mr-2"></i>
                                        <span className={"drawer-label"}>
                                            Уведомления
                                        </span>
                                    </NavLink>

                                    <NavLink to="/otzyv" className={"drawer-item  hideable"} activeClassName="drawer-item-selected">
                                        <i className="fas fa-thumbs-up mr-2"></i>
                                        <span className={"drawer-label"}>
                                    Отправить отзыв
                                </span>
                                    </NavLink>

                                    <NavLink to="/taxes" className={"drawer-item  hideable"} activeClassName="drawer-item-selected">
                                        <i className="fas fa-ruble-sign mr-2"></i>
                                        <span className={"drawer-label"}>
                                    Налоговый вычет
                                </span>
                                    </NavLink>

                                    <NavLink to="/settings" className={"drawer-item  hideable"} activeClassName="drawer-item-selected">
                                        <i className="fas fa-cog mr-2"></i>
                                        <span className={"drawer-label"}>
                                    Настройки
                                </span>
                                    </NavLink>
                                </>
                            }
                            {
                                !(this.props.fio.length > 0) &&
                                <NavLink exact to="/login" className={"drawer-item  hideable"} activeClassName="drawer-item-selected">
                                    <i className="fas fa-sign-in-alt mr-2"></i>
                                    <span className={"drawer-label"}>
                                        Вход
                                    </span>
                                </NavLink>
                            }

                            <NavLink exact to="/contacts" className={"drawer-item  hideable"} activeClassName="drawer-item-selected">
                                <i className="fas fa-fax mr-2"></i>
                                <span className={"drawer-label"}>
                                    Контакты
                                </span>
                            </NavLink>
                        </div>
                </div>
                        <div>
                            <hr className={"m-3"} />
                            <div className="text-center">
                                <a target={"_blank"} className="d-block my-3" href="https://itunes.apple.com/ru/app/%D0%B4%D0%BD%D0%BA-%D0%BA%D0%BB%D0%B8%D0%BD%D0%B8%D0%BA%D0%B0/id1456236716">
                                    <img src="/img/ios.png" height={50} alt=""/>
                                </a>

                                <a target={"_blank"} className="d-block pb-5" href="https://play.google.com/store/apps/details?id=ru.dnk174.lk">
                                    <img src="/img/android.png" height={50} alt=""/>
                                </a>
                            </div>
                        </div>

                </div>
            </div>
        );
    }
}