import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import config from '../components/config';
import Drawer from '../components/nav/Drawer';
import SecretRoute from "../components/SecretRoute";
import HomeScreen from "../Screens/HomeScreen";
import PriemyScreen from "../Screens/PriemyScreen";
import AnalizyScreen from "../Screens/AnalizyScreen";
import ErrorScreen from "../Screens/ErrorScreen";
import Login from "../components/auth/Login";
import LogOutScreen from "../Screens/LogOutScreen";
import NaznacheniaScreen from "../Screens/NaznacheniaScreen";
import ContactScreen from "../Screens/ContactScreen";
import OnlineScreen from "../Screens/OnlineScreen";
import OtzyvScreen from "../Screens/OtzyvScreen";
import TaxScreen from "../Screens/TaxScreen";
import SettingsScreen from "../Screens/SettingsScreen";
import RegScreen from "../Screens/RegScreen";
import BonusScreen from "../Screens/BonusScreen";
import MedKarta from "../Screens/MedKarta";
import PastPriemyScreen from "../Screens/PastPriemyScreen";
import NotificationsScreen from "../Screens/NotificationsScreen";

class MainLayout extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            auth: false,
            fio: ""
        }
    }

    render() {

        var data = sessionStorage.getItem('userData');
        var fio = "";
        if(data != null){
          data = JSON.parse(data);
          fio = data["ФИО"];
        }


    return (
        <div className={"wrapper"}>
            <Drawer fio={fio} />
            <Switch>
                <SecretRoute exact path="/" component={HomeScreen} authCheck={(res) => this.setState({auth: res})} />
                <SecretRoute exact path="/notifications" component={NotificationsScreen} />

                <SecretRoute exact path="/medkarta" component={MedKarta} />
                <SecretRoute exact path="/medkarta/zapisi" component={PriemyScreen} />
                <SecretRoute exact path="/medkarta/priemy" component={PastPriemyScreen} />
                <SecretRoute exact path="/medkarta/analizy" component={AnalizyScreen} />
                <SecretRoute exact path="/medkarta/naznacheniya" component={NaznacheniaScreen} />

                <SecretRoute exact path="/otzyv" component={OtzyvScreen} />
                <SecretRoute exact path="/taxes" component={TaxScreen} />
                <SecretRoute exact path="/settings" component={SettingsScreen} />
                <SecretRoute path="/online" exact component={OnlineScreen} />
                <Route path="/reg/:tel" exact component={RegScreen} />
                <Route path="/login" exact component={Login} setFio={( fio) => this.setState({fio: fio}) }/>
                <Route path="/logout" exact component={LogOutScreen} />
                <Route path="/bonuses" exact component={BonusScreen} />
                <Route path="/contacts" exact component={ContactScreen} />
                <Route path="/" component={ErrorScreen} />
            </Switch>
        </div>);
    }
}

export default withRouter(MainLayout);