import React, { Component } from 'react';
import axios from 'axios';
import {Redirect, Route, Link} from "react-router-dom";
import config from '../config';
import LoadingIndicator from "../LoadingIndicator";

export default class Login  extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            tel: "8",
            pass: "",
            step: 1,
            profiles: [],
            profile: "",
            loading: false,
            auth: false,
            error: false,
            resetPswd: false,
            reg: false
        }
    }

    telChange = (event) => {
        const target = event.target;
        let value = target.value.replace(/\D+/gi, "");

        if(value.length === 0){
            value = "8";
        }
        else {
            value = "8" + value.substr(1);
        }

        if(value.length === 11){
            this.checkProfiles(value);
        }
        else {
            this.setState({
                profile: "",
                profiles: [],
                error: false,
                pass: "",
                resetPswd: false
            });
        }

        this.setState({
            tel: value
        });
    };

    checkProfiles = (phone) => {
        this.setState({
            loading: true
        });
        let data = "userPhone=" + phone;
        axios.post('https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/get/clients_list', data)
            .then((res) => {
                if(res.data.length == 0 ){
                    this.setState({
                        profiles: [],
                        user: [],
                        loading: false
                    });
                }

                if(res.data.length == 1 ){
                    this.setState({
                        profile: res.data[0].Клиент,
                        birthDay: res.data[0].ДатаРождения,
                        step: 3,
                        loading: false
                    });
                }
                if(res.data.length > 1 ) {
                    this.setState({
                        profiles: res.data,
                        profile: res.data[0].Клиент,
                        birthDay: res.data[0].ДатаРождения,
                        step: 2,
                        loading: false
                    });
                }
            })
            .catch((err) => {
                this.setState({loading: false});
                console.log("Ошибка checkName: "+err.message);
            });
    };

    auth = (e) => {
        e.preventDefault();
        this.setState({loading: true});
        let data = "login="+this.state.tel + "&password=" + this.state.pass + "&uguid=" + this.state.profile;

        axios({
            method: 'post',
            url: 'https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/account/auth',
            data: {
                login: this.state.tel,
                password: this.state.pass,
                uguid: this.state.profile
            },
            withCredentials: true
        }).then((res) => {
            //console.log(res);
            //console.log(res.headers);
            this.setState({loading: false});

            if(res.data.error){
                this.setState({
                    auth: false,
                    error: true
                });
            }
            else{
                if(res.data["ФИО"].length) {
                    config.fio = res.data["ФИО"];
                    config.auth = true;
                    sessionStorage.setItem('userData', JSON.stringify(res.data));
                    sessionStorage.setItem('userPhone', this.state.tel);
                    sessionStorage.setItem('userProfile', this.state.profile);
                    sessionStorage.setItem('userEmail', res.data["ЭлПочта"]);
                    sessionStorage.setItem('userFIO', res.data["ФИО"]);
                    sessionStorage.setItem('userBirthday', this.state.birthDay);

                    //window._setData(res.data["ФИО"], res.data["ЭлПочта"], this.state.tel);
                    if(window.jivo_api){
                        window.jivo_api.setContactInfo({
                            "name": res.data["ФИО"],
                            "email": res.data["ЭлПочта"],
                            "phone": this.state.tel
                        });
                    }

                    this.setState({
                        loading: false,
                        auth: true
                    });
                }
            }
        })
            .catch((err) => {
                this.setState({loading: false, error: true});
                console.log("Ошибка tryToAuth: "+err.message);
            });
    };

    resetPswd = () => {
        this.setState({
            error: false
        });

        let data = "uguid=" + this.state.profile;
        axios.post('https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/account/resetPswd', data)
            .then((res) => {

                this.setState({
                    resetPswd: true
                });
            })
            .catch(function (err) {
                console.log("Ошибка сброса пароля: "+err.message);
            });
    };

    render(){

        let content = (
            <div className="blue-form dnk-blue-pattern shadow p-4 flip-card-front">
                <p className="text-left">Телефон (начиная с 8)</p>
                <div className="form-group input-group-lg d-flex align-items-center text-left">
                    <input
                        type="text"
                        className={"form-control"}
                        maxLength={11}
                        name={"tel"}
                        required
                        disabled={(this.state.loading)}
                        placeholder="8"
                        value={this.state.tel}
                        onChange={this.telChange}
                    />
                    {
                        (this.state.loading) &&
                        <div className="input-group-append ml-3">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                </div>

                {
                    (this.state.profiles.length > 0) && (
                        <div className="form-group input-group-lg text-left">
                            <label>Выберите профиль</label>
                            <select
                                className={"form-control"}
                                name="profile"
                                defaultValue={this.state.profile}
                                onChange={(e) => this.setState({
                                    profile: e.target.value,
                                    step: 3
                                })}
                            >
                                {this.state.profiles.map((u,i)=>{
                                    let dr = new Date() - new Date(u["ДатаРождения"]);
                                    let age = new Date(dr).getFullYear() - 1970;
                                    let text = "лет";
                                    if(age%10 > 1 && age%10 < 5) text = "года";
                                    if(age%10 == 1) text = "год";

                                    return (
                                        <option value={u['Клиент']} key={i}>
                                            {u['Имя']} ({age} {text})
                                        </option>
                                    );
                                })}
                                <option value={"-1"}>
                                    Зарегистрироваться
                                </option>
                            </select>
                        </div>
                    )
                }

                {
                    (!this.state.loading && this.state.tel.length === 11 && (this.state.profile === "" || this.state.profile === "-1" )) &&
                    <div className="">
                        {
                            (this.state.profiles.length === 0) &&
                            <p className="my-3 alert alert-warning">
                                Пользователь с таким номером телефона не найден.
                            </p>
                        }
                        <Link
                            to={"/reg/" + this.state.tel}
                            className={"btn dnk-pink rounded"}
                        >
                            {
                                (this.state.profile === "-1") ? "Зарегистрироваться" : "Зарегистрироваться"
                            }
                        </Link>
                    </div>
                }

                {(this.state.profile !== "" && this.state.profile !== "-1") ? (
                    <div>
                        <div className="form-group input-group-lg text-left">
                            <label>Введите пароль</label>
                            <input
                                type="password"
                                className={"form-control"}
                                name={"pass"}
                                required
                                value={this.state.pass}
                                onChange={(e) => this.setState({pass: e.target.value, error: false})}
                            />
                            <small>
                                Пароль указан в приложении к договору.
                                <br />
                                Если Вы забыли пароль — мы пришлем Вам новый по СМС.
                            </small>
                        </div>

                        {(this.state.error) ? (
                            <div className="form-group input-group-lg rounded dnk-pink p-3">
                                Проверьте правильность введённого пароля и попробуйте ещё раз.
                            </div>
                        ) : null}

                        {(this.state.resetPswd) ? (
                            <div className="form-group input-group-lg rounded dnk-pink p-3">
                                Новый пароль был отправлен в СМС на номер {this.state.tel}
                            </div>
                        ) : null}

                        <div className="form-group input-group-lg">
                            <button
                                type="submit"
                                className="btn btn-light rounded px-5 btn-lg"
                            >
                                Войти
                            </button>
                        </div>

                        {(this.state.resetPswd) ? null : (
                            <div className="form-group input-group-lg">
                                <button
                                    onClick={this.resetPswd}
                                    className={"btn btn-outline-light rounded mt-5"}
                                >
                                    Восстановить пароль
                                </button>
                            </div>
                        )}

                        <Link
                            to={"/reg/" + this.state.tel}
                            className={"btn text-dark rounded"}
                        >
                            <b>Зарегистрироваться</b>
                        </Link>
                    </div>
                ) : null
                }
            </div>
        );

        if(this.state.reg){
            return (
                <Redirect to='/reg' />
            );
        }

        if(this.state.auth){
            return (
                <Redirect to='/' />
            );
        }
        else{
            return(
                <div className={"content mx-auto"}>
                <div className={"mb-5 mt-3 login-form"}>
                    <h1 className={"text-center mb-4 d-none"}>
                        Вход в личный кабинет
                    </h1>

                    <div className={"d-flex mx-auto mb-4 justify-content-around"}>
                        <img src="https://doctorzabota.ru/upload/CMedc2/c2c/c2cc68cca00f1ec47dcaa0e32d2ae843.svg" height={50} alt=""/>
                        <img src="img/dip.png" height={50} alt=""/>
                    </div>

                    <div className={"text-center mb-4"}>
                        <img src="https://dnk174.ru/bitrix/templates/dnk174.ru/images/logo.png" height={80} alt=""/>
                    </div>

                    <form onSubmit={this.auth} autoComplete={"off"} className={"my-5"}>
                        <div className="mx-1 mx-lg-4">
                            <div className={"flip-card"}>
                                <div className={ (this.state.step > 100) ? "flip-card-inner flipped" : "flip-card-inner"}>
                                    <div className="mb-2">
                                        Введите номер телефона для того, чтобы войти или зарегистрироваться.
                                    </div>

                                    {
                                        (this.state.loading1) ?
                                            <LoadingIndicator /> : content
                                    }


                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                </div>
            );
        }
    }
}