import React, { Component } from 'react';
import axios from "axios";
import LoadingIndicator from '../components/LoadingIndicator';
import AnalizRow from "../components/analizy/AnalizRow";

export default class AnalizyScreen extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            loading: true,
            data: [],
            type: "future"
        }
    }

    componentDidMount() {
        axios({
            method: 'get',
            url: 'https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/get/analyzes',
            withCredentials: true
        }).then((res) => {

            this.setState({
                loading: false,
                data: res.data
            })
        })
            .catch(function (err) {
                //this.setState({loading: false});
                console.log("Ошибка tryToAuth: "+err.message);
            });
    }

    render(){

        if(this.state.loading)
            return(
                <div className={""}>
                    <h3 className={"text-left"}>Результаты анализов</h3>
                    <div className={"mt-3"}>
                        <button
                            className={"btn dnk-blue rounded"}
                            onClick={() => this.props.history.push("/medkarta")}
                        >
                            <i className="fas fa-chevron-left mr-2"></i>Назад
                        </button>
                    </div>
                    <div className={"d-flex align-items-center justify-content-center mt-5"}>
                        <LoadingIndicator />
                    </div>
                </div>

                );
        else
        return (
            <div className={""}>
                <h3 className={"text-left"}>Результаты анализов</h3>
                <div className={"mt-3"}>
                    <button
                        className={"btn dnk-blue rounded"}
                        onClick={() => this.props.history.push("/medkarta")}
                    >
                        <i className="fas fa-chevron-left mr-2"></i>Назад
                    </button>
                </div>
                    {
                        (this.state.data.length > 0) ?
                            this.state.data.map((u,i)=>{
                                return (
                                    <AnalizRow
                                        key={i}
                                        i={i}
                                        date={u['ДатаГотовности']}
                                        link={u['Анализ']}
                                        name={u['Наименование']}
                                    />
                                )
                            })
                         : (
                                <div className={"mt-5 d-flex text-center flex-column justify-content-center align-items-center"}>
                                    <p><i className="fas fa-search fa-5x mb-3"></i></p>
                                    <h3>У вас пока нет готовых анализов</h3>
                                </div>
                            )
                    }
            </div>
        );
    }
}