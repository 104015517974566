import React, { Component } from 'react';
import axios from "axios";
import LoadingIndicator from "../LoadingIndicator";

export default class AskDoctor extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            loading: false,
            text: "",
            fio: "",
            email: "",
            tel: "",
            saveSuccess: false,
            saveError: false,
            reviewError: false,
            data: []
        }
    }

    componentDidMount() {
        this.setState({
            tel: sessionStorage.getItem('userPhone'),
            email: sessionStorage.getItem('userEmail'),
            fio: sessionStorage.getItem('userFIO')
        });
    }

    telChange = (event) => {
        const target = event.target;
        let value = target.value.replace(/\D+/gi, "");

        if(value.length == 0){
            value = 8;
        }

        this.setState({
            tel: value
        });
    };

    send = () => {
        this.setState({loading: true});
        let data = "review=" + this.state.text + "&fio=" + this.state.fio + "&phone=" + this.state.tel + "&email=" + this.state.email + "&doc=" + this.props.doc + ". ";

        axios("https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/set/askDoc", {
            method: "post",
            data: data,
            withCredentials: true
        })
            .then((res) => {
                res.data.success ?
                    this.setState({
                        saveSuccess: true,
                        saveError: false,
                        reviewError: false,
                        loading: false
                    })
                    :
                    this.setState({ saveError: true, loading: false });
            })
            .catch(function (err) {
                console.log("Ошибка Send Review: "+err.message);
                this.setState({ saveError: true });
            });
    };

    render() {
        if(this.state.loading){
            return (
                <div className={"d-flex align-items-center justify-content-center mt-5"}>
                    <LoadingIndicator />
                </div>
            );
        }
        if(!this.state.loading && this.state.saveSuccess){
            return (
                <div className={"my-3 d-flex flex-column justify-content-center align-items-center"}>
                    <p>
                        <i className="far fa-check-circle fa-5x mb-3"></i>
                    </p>
                    <h4 className={"text-center"}>
                        Ваш вопрос успешно отправлен
                    </h4>
                </div>
            );
        }
        if(!this.state.loading && this.state.saveError){
            return (
                <div className={"my-3 d-flex flex-column justify-content-center align-items-center"}>
                    <p>
                        <i className="fas fa-exclamation-circle fa-5x mb-3"></i>
                    </p>
                    <h4 className={"text-center"}>
                        Не удалось отправить вопрос.
                    </h4>
                    <h4 className={"text-center"}>
                        Попробуйте еще раз позднее.
                    </h4>
                </div>
            );
        }
        if(!this.state.loading && !this.state.saveError && !this.state.saveSuccess){
            return (
                <div className={"row"}>
                    <div className="form-group input-group-lg col-12">
                        <label>Ваш вопрос врачу</label>
                        <textarea
                            type="text"
                            rows={10}
                            className={"form-control"}
                            name={"text"}
                            value={this.state.text}
                            onChange={(e) => this.setState({text: e.target.value})}
                            required={true}
                        />
                    </div>
                    <div className="form-group input-group-lg col-12">
                        <label>Ваши ФИО</label>
                        <input
                            type="text"
                            className={"form-control"}
                            name={"fio"}
                            value={this.state.fio}
                            onChange={(e) => this.setState({fio: e.target.value})}
                            required={true}
                        />
                    </div>
                    <div className="form-group input-group-lg col-lg-6">
                        <label>Контактный телефон</label>
                        <input
                            type="text"
                            className={"form-control"}
                            name={"tel"}
                            maxLength={11}
                            value={this.state.tel}
                            onChange={this.telChange}
                            required={true}
                        />
                    </div>
                    <div className="form-group input-group-lg col-lg-6">
                        <label>Ваш email</label>
                        <input
                            type="email"
                            className={"form-control"}
                            name={"email"}
                            value={this.state.email}
                            onChange={(e) => this.setState({email: e.target.value})}
                            required={true}
                        />
                    </div>
                    <div className="form-group input-group-lg text-center col-12 ">
                        <button
                            type="button"
                            className="btn rounded dnk-pink my-2"
                            disabled={(this.state.text.length > 10 && this.state.fio.length > 3 && this.state.email.length > 3 && this.state.tel.length > 6) ? false : true}
                            onClick={this.send}
                        >
                            Отправить вопрос
                        </button>
                    </div>

                </div>
            );
        }


    }
}