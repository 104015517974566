import React, { Component } from 'react';
import Raspisanie from './Raspisanie';
import AskDoctor from './AskDoctor';
import FuturePriemy from "../priemy/FuturePriemy";
import PastPriemy from "../priemy/PastPriemy";
import axios from "axios";

export default class Doctor extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            loading: true,
            data: [],
            services: [],
            type: "rasp"
        }
    }
    render(){
        let doctor = this.props.doc;
        doctor["ОбщаяИнформация"] = doctor["ОбщаяИнформация"].replace(/\t/g, '\n');
        doctor["ОбщаяИнформация"] = doctor["ОбщаяИнформация"].replace(//g, '');

        let content = null;

        if(this.state.type === "rasp")
            content =
                <div className={"mt-3 p-3 p-xl-4 bg-white shadow rounded"} style={{maxWidth: 650}}>
                    <Raspisanie
                        doc={this.props.doc}
                        spec={this.props.spec}
                        filial={this.props.filial}
                    />
                </div>;

        if(this.state.type === "info")
            content = <div className={"mt-3 p-3 p-xl-4 bg-white shadow rounded"}>
                        <div className={"mb-5"} style={{whiteSpace: "pre-line"}}>
                            {doctor['ОбщаяИнформация']}
                        </div>
                </div>;
        if(this.state.type === "ask")
            content = <div className={"mt-3 p-3 p-xl-4 shadow bg-white rounded"} style={{maxWidth: 650}}>
                <AskDoctor
                    doc={this.props.doc['Фамилия'] + " " + this.props.doc['Имя'] + " " + this.props.doc['Отчество']}
                />
        </div>;

        return (
            <div className={"content"}>
                <h3 className={"text-left"}>{this.props.doc['Фамилия'] + " " + this.props.doc['Имя'] + " " + this.props.doc['Отчество']}</h3>
                <div className={"mt-3"}>
                    <button
                        className={"btn dnk-blue rounded"}
                        onClick={() => this.props.back()}
                    >
                        <i className="fas fa-chevron-left mr-2"></i>
                        Назад
                    </button>
                </div>
                <div className={"row my-3"}>
                    <div className={"col-md-3 d-flex flex-column align-items-center mb-5 pt-3"}>
                        <div className={"text-center rounded overflow-hidden"}>
                            {
                                (this.state.imgError) ? (
                                    <i className="fas fa-user-md fa-7x mt-3 mt-md-5"></i>
                                ) : (
                                    <img
                                        className={"img-fluid"}
                                        style={{maxHeight: 300}}
                                        src={"/img/vrachi/" + this.props.doc['Фамилия'] + " " + this.props.doc['Имя'] + " " + this.props.doc['Отчество'] + ".png"}
                                        alt="Фото врача скоро будет размещено"
                                        onError={()=>this.setState({imgError: true})}
                                    />
                                )
                            }
                        </div>
                    </div>
                    <div className={"col-md-9 mb-5 px-0"}>
                        <div>
                            <div className="btn-group">
                                <button
                                    type="button"
                                    className={
                                        (this.state.type === "rasp") ?
                                            ("btn rounded dnk-blue mr-3 my-2") :
                                            ("btn btn-outline-secondary mr-3 rounded my-2")
                                    }
                                    onClick={()=>{this.setState({type: "rasp"})}}
                                >
                                    Расписание
                                </button>
                                <button
                                    type="button"
                                    className={
                                        (this.state.type == "info") ?
                                            ("btn rounded dnk-blue mr-3 my-2") :
                                            ("btn btn-outline-secondary mr-3 rounded my-2")
                                    }
                                    onClick={()=>{this.setState({type: "info"})}}
                                >
                                    О враче
                                </button>
                                <button
                                    type="button"
                                    className={
                                        (this.state.type === "ask") ?
                                            ("d-none btn rounded dnk-blue my-2") :
                                            ("d-none btn btn-outline-secondary rounded my-2")
                                    }
                                    onClick={()=>{this.setState({type: "ask"})}}
                                >
                                    Задать вопрос
                                </button>
                            </div>
                        </div>
                        {content}
                    </div>
                </div>
            </div>
        );
    }
}