import React, { Component } from 'react';
import axios from "axios";
import FuturePriemy from "../components/priemy/FuturePriemy";
import PastPriemy from "../components/priemy/PastPriemy";
import LoadingIndicator from '../components/LoadingIndicator';
import NaznRow from "../components/naznachenia/NaznRow";
import AnalizRow from "../components/analizy/AnalizRow";

export default class NaznacheniaScreen extends Component {
    constructor()
    {
        super(...arguments);
        this.state = {
            loading: true,
            priemy: [],
            type: "future"
        }
    }

    componentDidMount() {
        axios({
            method: 'get',
            url: 'https://xn--d1ahcbgcflfgbyy8d1c.xn--p1ai/get/sheets_appointments',
            withCredentials: true
        }).then((res) => {

            this.setState({
                loading: false,
                data: res.data
            })
        })
            .catch(function (err) {
                //this.setState({loading: false});
                console.log("Ошибка tryToAuth: "+err.message);
            });
    }

    render(){
        var priemy = null;
        var rows = null;

        if(this.state.loading)
            return(
                <div className={""}>
                    <h3 className={"text-left"}>Назначения</h3>
                    <div className={"mt-3"}>
                        <button
                            className={"btn dnk-blue rounded"}
                            onClick={() => this.props.history.goBack()}
                        >
                            <i className="fas fa-chevron-left mr-2"></i>Назад
                        </button>
                    </div>
                    <div className={"d-flex align-items-center justify-content-center mt-5"}>
                        <LoadingIndicator />
                    </div>
                </div>

            );
        else
            return (
                <div className={""}>
                    <h3 className={"text-left"}>Назначения</h3>
                    <div className={"mt-3"}>
                        <button
                            className={"btn dnk-blue rounded"}
                            onClick={() => this.props.history.push("/medkarta")}
                        >
                            <i className="fas fa-chevron-left mr-2"></i>Назад
                        </button>
                    </div>
                    {
                        (this.state.data.length > 0) ?
                            this.state.data.map((u,i)=>{

                                return (
                                    <NaznRow
                                        key={i}
                                        i={i}
                                        date={u['Дата']}
                                        link={u['ЛистНазначений']}
                                        name={u['Врач']}
                                    />
                                )
                            })
                            : (
                                <div className={"mt-5 d-flex text-center flex-column justify-content-center align-items-center"}>
                                    <p><i className="fas fa-search fa-5x mb-3"></i></p>
                                    <h3>У вас пока нет назначений</h3>
                                </div>
                            )
                    }

                </div>
            );
    }
}